
import React from "react";
import {
    View,
    Text,
    TouchableOpacity,
    ImageBackground,
    Animated,
    ScrollView
} from "react-native";
import { StyleSheet } from 'react-native';
import LatoText from "../../components/LatoText";
import { useState } from "react";
import InitiativesBook from "./InitiativesBook";
import InitiativesPersonal from "./personal/InitiativesPersonal";
import BackToMenu from "../../components/BackToMenu";
import useImage from '../../hooks/image.js';
import useGeneralizeColor from '../../hooks/generalizeColor';
import HeaderRight from '../../components/HeaderRight';
import SanduicheMenu from '../sanduicheMenu/SanduicheMenu';
import { useNavigation, useRoute } from "@react-navigation/native";

const InitiativesBoard = () => {
    const route = useRoute();
    const initialPage = route.params?.initialPage || "Book";
    const [selectedPage, setSelectedPage] = useState(initialPage);
    const togglePage = () => {
        setSelectedPage({ "Book": "Personal", "Personal": "Book" }[selectedPage]);
    }
    const { image: bg, loading: bgLoading } = useImage('bg.png')
    const { rankingColors } = useGeneralizeColor();
    const [menuVisible, setMenuVisible] = useState(false);
    const navigation = useNavigation();

    return (
        <View style={{ flex: 1, }}>
            <HeaderRight onPress={() => setMenuVisible(true)} />
            <BackToMenu />
            <ImageBackground source={bg} style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>

                <ScrollView style={{ flex: 1, width: '100%' }}>
                    <Animated.View style={[styles.header]}>
                        <TouchableOpacity onPress={togglePage}>
                            <View style={[styles.buttonContainer, { backgroundColor: "#005248" }]}>
                                <LatoText style={[selectedPage == "Book" ? { backgroundColor: "#049C5C" } : null, styles.botoes]}>Livro de Iniciativas</LatoText>
                                <LatoText style={[selectedPage == "Personal" ? { backgroundColor: "#049C5C" } : null, styles.botoes]}>Minhas Iniciativas</LatoText>
                            </View>
                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={[styles.footer]}>
                        <LatoText style={{ color: "#fff" }}>
                            {selectedPage === "Book" ? <InitiativesBook /> : <InitiativesPersonal />}
                        </LatoText>
                    </Animated.View>

                </ScrollView>

            </ImageBackground>
            {menuVisible && <SanduicheMenu onMenuItemPress={(screen) => {
                setMenuVisible(false);
                navigation.navigate(screen);
            }} onClose={() => setMenuVisible(false)} />}
        </View>
    );

};

export default InitiativesBoard;

const styles = StyleSheet.create({
    header: {
        height: 60,
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 100,
        width: '100%',
        //height: '46.44px',
    },
    botoes: {
        padding: 10,
        borderRadius: 30,
        color: "#FFF9E3",
        textAlign: "center",
        whiteSpace: "nowrap",
        minWidth: 130,
        fontSize: 14,
        lineHeight: '16.8px',
        height: '40px',
    },
    buttonContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 30,
        padding: 3,
    },
    footer: {
        marginTop: 60,
        alignItems: 'center',
        justifyContent: "center"
    },
});
