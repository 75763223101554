
import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import MainRouter from './MainRouter';
import LandingPage from '../pages/landing/Landing';
import LoginPage from '../pages/landing/auth/Login';
import NewUserPage from '../pages/landing/auth/NewUser';
import { useAuth } from '../hooks/auth';
import { ConnectionsProvider } from '../hooks/connections';

const AuthStack = createNativeStackNavigator();

const AuthRouter = () => (
    <AuthStack.Navigator 
        screenOptions={{
            headerShown: false
        }}>
        <AuthStack.Screen 
            name="LandingPage" 
            component={LandingPage}
        />
        <AuthStack.Screen
            name="LoginPage"
            component={LoginPage}
        />
        <AuthStack.Screen
            name="NewUserPage"
            component={NewUserPage}
        />
    </AuthStack.Navigator>
);

const RootRouter = () => {
    const { user } = useAuth();
    if(user) {
        return(
        <ConnectionsProvider>
            <MainRouter />
        </ConnectionsProvider>);
    }
    return <AuthRouter />;
};

export default RootRouter;