import React, { useCallback, useState } from 'react';
import { Animated, Easing, StyleSheet, Text, TouchableWithoutFeedback, View, Image } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { BookComponentsProps } from '.';
import { useFocusEffect } from '@react-navigation/native';
import useImage from '../../hooks/image';
import { position } from 'native-base/lib/typescript/theme/styled-system';

interface ICoverProps<T> extends BookComponentsProps<T>{
  setOpened: (opened: boolean) => void;
  isBookOpened: boolean;
  bookCover?: any;
  coverColors?: string[];
  autoOpen: T;
}

function Cover<T>({setOpened, bookCover, coverColors, baseStyles, isBookOpened, autoOpen}: ICoverProps<T>){
  const [rotateYValue] = useState(new Animated.Value(0));
  const [isPageAt90Degrees, setIsPageAt90Degrees] = useState(false);
  function open() {
    Animated.timing(rotateYValue, {
      toValue: 1,
      duration: 1200,
      easing: Easing.inOut(Easing.linear),
      useNativeDriver: false,
    }).start(() => {
      setOpened(true);
      setIsPageAt90Degrees(false);
    });
  }

  function close() {
    setOpened(false);
    Animated.timing(rotateYValue, {
      toValue: 0,
      duration: 1200,
      easing: Easing.inOut(Easing.linear),
      useNativeDriver: false,
      delay: 100,
    }).start(() => setIsPageAt90Degrees(false));
  }
  
  useFocusEffect(useCallback(() => {
    if(isBookOpened && autoOpen) {
      rotateYValue.setValue(1);
      setIsPageAt90Degrees(true);
    }
  }, [isBookOpened]));

  useFocusEffect(useCallback(() => {
    return () => {
      rotateYValue.setValue(0);
      setIsPageAt90Degrees(false);
    };
  }, []));
  
  const rotY = rotateYValue.interpolate({
    inputRange: [0, 0.9, 1],
    outputRange: ['0deg', '-90deg', '-180deg']
  });

  const opacity = rotateYValue.interpolate({
    inputRange: [0, 0.5, 0.5],
    outputRange: [1, 1, 0]
  });

  const opacityClosed = rotateYValue.interpolate({
    inputRange: [0, 0.9],
    outputRange: [1, 0],
    extrapolate: 'clamp'
  });
  
  const opacityOpen = rotateYValue.interpolate({
    inputRange: [0.9, 1],
    outputRange: [0, 1],
    extrapolate: 'clamp'
  });

  const { image: bookTextureIMG } = useImage('BookCoverTexture.png');
  return (
    <TouchableWithoutFeedback onPress={() => !isBookOpened ? open() : close()}>
    <Animated.View style={[baseStyles.root, { zIndex: !isBookOpened ? 10000 : 980, width: 840, marginLeft: -420, transform: [{ perspective: 2500 }, { rotateY: rotY }] }]}>
  <View style={[baseStyles.cover_base, { marginLeft: 420 }, coverColors ? { backgroundColor: coverColors[0] } : {}]}>
    <LinearGradient
      colors={coverColors || ['#013A63', '#01497C', '#014F86', '#01497C']}
      start={{ x: 0.0, y: 0.6 }}
      end={{ x: 1.0, y: 0.4 }}
      style={{ height: 690 }}
    />
    {!isBookOpened && (
      <Animated.View style={[{ opacity: opacityClosed, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }]}>
        <Image source={bookCover} style={{ width: 420, height: 690 }} />
      </Animated.View>
    )}
    <Animated.View style={[{ opacity: opacityOpen, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }]}>
      <Image source={bookTextureIMG} style={{ width: 420, height: 690 }} />
    </Animated.View>
    {isBookOpened && (
      <Image source={bookTextureIMG} style={{ width: 420, height: 690, position: 'relative', top: 0, bottom: 0, left: 0, right: 0 }} />
    )}
  </View>
</Animated.View>
  </TouchableWithoutFeedback>
  );
}

export default Cover;