import React, {useMemo, useCallback, useState} from 'react';
import {
    View,
    StyleSheet,
    FlatList,
    Platform,
    ImageBackground,
    Dimensions,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import {IconButton, Snackbar} from 'react-native-paper';
import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native';
import api from '../../util/axios';
import ProfileInfoItem from '../../components/ProfileInfoItem';
import * as Linking from 'expo-linking';
import LatoText from '../../components/LatoText';
import brasaBg from '../../assets/brasabg.png';
import ArcoBrasa from '../../components/ArcoBrasa';
import {FontAwesome5, MaterialCommunityIcons, MaterialIcons} from '@expo/vector-icons';
import EducationInfoItem from '../../components/EducationInfoItem';
import Svg, {Path} from 'react-native-svg';
import {useAuth} from '../../hooks/auth';
import LogoutModal from '../../components/LogoutModal';
import QRCode from "react-native-qrcode-svg";
import {useConference} from "../../hooks/conference";
import HeaderRight from '../../components/HeaderRight';
import SanduicheMenu from '../sanduicheMenu/SanduicheMenu';

const Profile = () => {
    const [info, setInfo] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [showEducation, setShowEducation] = React.useState(false);
    const [logoutModal, setLogoutModal] = React.useState(false);
    const [error, setError] = React.useState([false, null]);
    const navigation = useNavigation();
    const route = useRoute();
    const auth = useAuth();
    const [menuVisible, setMenuVisible] = useState(false);
    const { currentSelectedConference } = useConference();

    const {height} = Dimensions.get("screen");
    const height_logo = height * 0.18;
    const {participantId} = useConference();

    useFocusEffect(useCallback(() => {
        async function fetchProfile() {
            try {
                setLoading(true);
                const {data} = await api.get('/webapp/profile');
                setInfo(data.user);
                navigation.setParams({
                    info: data.user
                })
                setLoading(false);
            } catch (err) {
                setError([true, err.message]);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }

        fetchProfile();
    }, []));

    const fields = useMemo(() => [
        {
          icon: () => <MaterialIcons name="email" size={20}/>,
          value: `${info?.email}`,
        },
        {
          icon: () => <FontAwesome5 name="birthday-cake" size={20} />,
          value: `${info?.birthDate.split('-')[2]}/${info?.birthDate.split('-')[1]}/${info?.birthDate.split('-')[0]}`,
        },
        {
          icon: () => <MaterialIcons name="flag" size={22} color="black" />,
          value: `${info?.nationality.map(item => item.nationalityName).join(", ")}`,
        },
        {
          icon: () => <MaterialCommunityIcons name="home-city" size={20} />,
          value: `${info?.birthPlace}`,
        },
        {
          icon: () => <MaterialCommunityIcons name="phone" size={24} color="black" />,
          value: `+${info?.phoneNumberCountryCode} ${info?.phoneNumber}`,
        }
      ], [info]);

    const education = useMemo(() => {
      if(!info || !info.education){
        return [];
      }
      const fields = info.education.sort(item => item.type).map((item, index) => {
        return {
          label: item.name,
          value: `${item.primaryFieldOfStudy}${item.fieldOfStudy2 ? `, ${item.fieldOfStudy2}` : ''} -  ${item.type}`,
        }
      });
      return fields;
    }, [info && info.education]);

    if (error[0]) {
        return (
            <>
                <Snackbar
                    visible={error[0]}
                    onDismiss={() => setError([false, ''])}
                    action={{
                        label: 'Dismiss',
                        onPress: () => {
                            setError([false, ''])
                        },
                    }}
                    style={{
                        borderRadius: 50,
                        backgroundColor: '#df4759'
                    }}
                    wrapperStyle={{top: 0}}
                >
                    {error[1]}
                </Snackbar>
            </>)
    }
    return (<>
            <LogoutModal visible={logoutModal} setVisible={setLogoutModal}/>
            <ImageBackground style={styles.container} source={brasaBg}>
                {currentSelectedConference && 
                    <HeaderRight onPress={() => setMenuVisible(true)} />
                }
                {!loading && (
                    <>
                        <View style={styles.header}>
                            <IconButton
                                onPress={() => navigation.navigate('ProfileEdit', {info: route.params?.info ?? {}})}
                                icon="pencil"
                                color={"#0072b1"}
                                size={30}
                            />
                            <IconButton
                                onPress={() => auth.signOut()}
                                icon="logout"
                                color={"#0072b1"}
                                size={30}
                            />
                        </View>

                        <Animatable.View animation="fadeInUp" style={styles.content}>
                            <ArcoBrasa innerStyles={{alignItems: 'center', paddingTop: 30}}>
                                <View style={{
                                    height: height_logo,
                                    width: height_logo,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: (height_logo / 2),
                                    backgroundColor: '#f2f2f2',
                                    borderWidth: 8,
                                    borderColor: '#0087CE',
                                    marginTop: -(height_logo * 0.85),
                                    marginBottom: 20
                                }}>
                                    <Svg
                                        style={{height: (height_logo * 0.6)}}
                                        viewBox="0 0 799.14 908.57"
                                    >
                                        <Path
                                            fill="#7ab341"
                                            d="M798.6 49.85v318.18c-.24.19-.34.33-.48.38-.16.06-.35.06-.52.04-.17-.02-.34-.08-.49-.16-.47-.23-.94-.46-1.39-.74-129.99-81.02-259.97-162.04-389.96-243.06-.14-.09-.34-.06-1.43-.25C269.95 207.68 135.31 292 0 376.38V50.25c8.77-2.37 17.44-4.92 26.22-7.04 14.56-3.5 29.19-6.66 43.63-10.74 10.18-2.88 20.81-4.17 31.24-6.14 6.5-1.23 13-2.49 19.52-3.56 10.3-1.69 20.63-3.24 30.95-4.84 6.01-.93 12.02-1.94 18.05-2.75 7.07-.94 14.16-1.67 21.24-2.55 9.15-1.13 18.29-2.32 27.42-3.5.86-.11 1.7-.47 2.56-.52 6.96-.44 13.93-.78 20.89-1.26 2.77-.19 5.53-.59 8.28-1 8.98-1.33 17.99-2.08 27.08-2.16 6.27-.06 12.54-.54 18.8-1.02 6.06-.47 12.09-1.33 18.15-1.75 4.53-.32 9.1-.12 13.65-.32C366.69-.58 405.73.15 444.75.16c12.92 0 25.88.19 38.76 1.19 10.95.86 21.86 1.81 32.86 2.07 8.49.2 17.02.85 25.42 2.04 11.23 1.59 22.53 2.09 33.78 3.21 9.33.93 18.59 2.61 27.9 3.82 6.9.89 13.84 1.48 20.74 2.38 6.21.81 12.38 1.85 18.57 2.8 9.8 1.51 19.61 3 29.41 4.57 7.04 1.13 14.08 2.29 21.08 3.6 10.1 1.88 20.2 3.8 30.25 5.9 9.2 1.92 18.37 4 27.52 6.17 14.58 3.45 29.13 7.03 43.68 10.59 1.17.29 2.29.78 3.88 1.34"
                                        />
                                        <Path
                                            fill="#f5dc05"
                                            d="M65.47 692.68c-2.48-3.09-4.49-5.24-6.11-7.65-8.19-12.17-16.48-24.28-24.32-36.68-4-6.32-7.37-13.09-10.56-19.87-3.7-7.89-7.16-15.92-10.22-24.07-4.29-11.42-7.57-23.14-10.21-35.1-2-9.08-2.36-18.28-3.69-27.39-.56-3.86.98-7.99.71-11.94-2.14-31.65-.34-63.34-.94-95-.04-1.92 0-3.85 0-5.59 4.51-4.24 389.45-244.72 404.45-252.69 131.24 81.37 262.69 162.86 394.56 244.61v5.04c-.01 36.97 0 73.93-.08 110.89 0 2.59-.6 5.17-.93 7.76-.81 6.38-1.24 12.85-2.54 19.13-1.94 9.36-4.43 18.6-6.83 27.86-4.39 16.88-11.64 32.65-20.06 47.76-8.16 14.64-17.04 28.91-27.23 42.3-.31.4-.61.82-.94 1.28-.68-.34-1.3-.44-1.53-.8-5.28-8.22-10.57-16.45-15.74-24.74-5-8-9.57-16.29-14.88-24.08-7.67-11.24-15.38-22.49-24.63-32.56-6.97-7.59-13.54-15.58-20.91-22.76-7.73-7.53-16-14.56-24.42-21.32-8.57-6.88-17.41-13.47-26.5-19.65-17.96-12.23-37.03-22.56-56.77-31.59-21-9.6-42.79-17.09-65.12-22.84-10.77-2.77-21.84-4.39-32.76-6.58-9.24-1.85-18.6-2.54-27.97-3.42-10.25-.96-20.46-2.24-30.76-1.77-5.89.27-11.75 1.17-17.63 1.75-1.39.14-2.79.17-4.19.23-8.36.34-16.63 1.27-24.83 3.07-10.54 2.32-21.26 3.87-31.73 6.44-20.53 5.04-40.49 11.78-59.97 20.11-19.69 8.41-38.63 18.21-56.55 29.79-12.44 8.04-24.3 17.04-35.98 26.17-8.79 6.87-17.33 14.18-25.17 22.1-11.17 11.29-21.5 23.41-30.76 36.34-8.14 11.35-16.01 22.92-23.46 34.73-5.2 8.26-9.47 17.1-14.21 25.66-2.62 4.73-5.35 9.39-8.58 15.06"
                                        />
                                        <Path
                                            fill="#1589cb"
                                            d="M96.76 727.81c3.69-7.35 7.08-14.46 10.79-21.4 5.11-9.56 10.37-19.05 15.74-28.47 8.49-14.89 17.72-29.33 28.12-42.96 5.29-6.93 11.13-13.46 18.21-18.78 2.91-2.19 5.15-5.25 7.91-7.68 8.66-7.61 17.16-15.43 26.21-22.56 19.32-15.22 40.66-27.22 62.79-37.82 13.7-6.57 27.81-12.19 42.26-16.98 10.78-3.57 21.59-6.99 32.78-8.99 6-1.07 12.03-1.98 18.06-2.81 7.75-1.06 15.52-1.99 23.29-2.93 1.39-.17 2.8-.17 4.19-.17 10.69-.01 21.39-.32 32.06.09 8.85.34 17.72.79 26.48 2.66 5.94 1.27 12.15 1.3 18.08 2.61 10.04 2.21 20.06 4.65 29.89 7.63 9.01 2.73 17.88 6.04 26.62 9.56 13.3 5.36 26.4 11.14 38.97 18.15 11.63 6.48 23.21 13.01 33.99 20.81 8.47 6.13 16.62 12.75 24.66 19.45 6.57 5.48 13.23 10.97 19.01 17.22 8.89 9.6 17.4 19.6 25.46 29.9 13.41 17.13 24.46 35.79 34.09 55.29 3.63 7.36 7.3 14.7 11.17 22.48-4.6 4.61-9.12 9.16-13.67 13.68-5.1 5.06-10.05 10.28-15.39 15.07-7.92 7.1-16.01 14.03-24.25 20.75-9.06 7.39-18.29 14.58-27.59 21.66-6.25 4.75-12.65 9.31-19.15 13.71-8.82 5.96-17.67 11.9-26.73 17.47-13.25 8.15-26.52 16.31-40.13 23.83-14.08 7.78-28.44 15.08-42.92 22.07-16.37 7.9-32.99 15.28-50.07 21.56-14.56 5.34-29.15 10.62-43.73 15.9-3.07 1.11-6.15.96-9.23-.16-14.78-5.34-29.65-10.46-44.36-16-25.74-9.69-50.47-21.64-74.88-34.24-18.48-9.55-36.46-19.98-54.14-30.96-17.07-10.61-33.81-21.7-49.86-33.77-11.71-8.8-22.93-18.27-34.43-27.36-13.47-10.65-25.71-22.62-37.76-34.8-.73-.74-1.41-1.53-2.51-2.72"
                                        />
                                    </Svg>
                                </View>
                                <LatoText
                                    black
                                    style={{
                                        fontSize: 24,
                                        color: "#000"
                                    }}>
                                    {info.firstName} {info.lastName}
                                </LatoText>
                                {info.pronouns && (<LatoText
                                    bold
                                    style={{
                                        fontSize: 16,
                                        color: "#5b5b5b"
                                    }}>
                                    ({info.pronouns}) {info?.gender && `● ${info?.gender}`}
                                </LatoText>)
                                }

                                {info.education && info.education.length !== 0 && (
                                    <LatoText bold italic style={{
                                        marginTop: 8,
                                        fontSize: 16,
                                        textAlign: 'center'
                                    }}>
                                        {info.education && info.education.length !== 0
                                            ? info.education[info.education.length - 1]['primaryFieldOfStudy'] +
                                            (info.education[info.education.length - 1]['fieldOfStudy2'] ? `, ${info.education[info.education.length - 1]['fieldOfStudy2']}` : '')
                                            + " @ " + info.education[info.education.length - 1]['name']
                                            : '...'}
                                    </LatoText>
                                )}
                                <View style={styles.linksContainer}>
                                    {info.resumeUrl && (
                                        <IconButton
                                            onPress={() => Platform.OS == 'web' ? window.open(`${api.getUri()}/webapp/user/getCV/${auth.user.id}?auth=${auth.user.auth.token}`, '_blank') : {}}
                                            icon="file-document"
                                            color={"#f5dc05"}
                                            size={32}
                                        />
                                    )}
                                    {info.linkedin && (
                                        <IconButton
                                            onPress={() => {
                                                Linking.openURL(info.linkedin);
                                            }}
                                            icon="linkedin"
                                            color={"#0072b1"}
                                            size={32}
                                        />
                                    )}
                                    <IconButton
                                        onPress={() => {
                                            setShowEducation(!showEducation);
                                        }}
                                        icon={showEducation ? "account" : "school"}
                                        color={"#000"}
                                        size={32}
                                    />
                                </View>
                                {<FlatList
                                    style={{marginTop: 16}}
                                    data={showEducation ? education : fields}
                                    keyExtractor={(item, index) => `${index}-${item.key}`}
                                    renderItem={({item}) => {
                                        if (showEducation) {
                                            return <EducationInfoItem label={item.label} value={item.value}/>
                                        }
                                        return <ProfileInfoItem icon={item.icon} value={item.value}/>
                                    }}
                                    contentContainerStyle={{
                                        paddingHorizontal: 30
                                    }}
                                />}
                                {participantId ?
                                    <View style={{marginTop: 10}}>
                                        <QRCode
                                            value={window.location.hostname + "/connect?participantId=" + participantId}
                                        />
                                    </View> : null}
                            </ArcoBrasa>
                        </Animatable.View>
                    </>)}
                    {menuVisible && <SanduicheMenu onMenuItemPress={(screen) => {
                        setMenuVisible(false);
                        navigation.navigate(screen);
                    }} onClose={() => setMenuVisible(false)} />}
            </ImageBackground>
        </>
    );
}

export default Profile;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        flex: 3,
        marginHorizontal: 16,
    },
    header: {
        flex: 0.8,
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    linksContainer: {
        flexDirection: 'row',
    },
});