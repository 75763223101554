import React from 'react'
import {View, ScrollView, TouchableOpacity} from 'react-native'
import {ProgressBar, IconButton} from 'react-native-paper';
import CVModal from '../../components/CVModal';
import {useRoute, useNavigation} from '@react-navigation/native';

import ArcoBrasa from '../../components/ArcoBrasaLados'
import LatoText from '../../components/LatoText';
import {MaterialCommunityIcons} from '@expo/vector-icons';
import MainProfile from './ProfileEdit/MainProfile';
import EducationProfile from './ProfileEdit/EducationProfile';

export default function ProfileEdit() {

    const navigation = useNavigation();

    const [save, setSave] = React.useState(false);
    const [showEducation, setShowEducation] = React.useState(false);

    return (
        <>

            <View style={{flex: 1, backgroundColor: "#1589cb", zIndex:999}}>
                {save ? <ProgressBar color="white" indeterminate/> : null}
                <View style={{height: 60, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <IconButton
                        onPress={() => {
                            navigation.pop(1)
                        }}
                        icon={'chevron-left'}
                        color={"#fff"}
                        size={36}
                    />
                    <View style={{flexDirection: 'row'}}>
                        <IconButton
                            onPress={() => {
                                setShowEducation(!showEducation)
                            }}
                            icon={showEducation ? "account" : "school"}
                            color={"#fff"}
                            size={30}
                        />
                    </View>
                </View>
                <View style={{flex: 1, backgroundColor: "#f5dc05"}}>
                    <ScrollView style={{
                        marginTop: 8,
                        paddingVertical: 30,
                        paddingHorizontal: 60,
                        backgroundColor: '#f2f2f2',
                        borderTopWidth: 6,
                        borderColor: '#7ab341',
                        flex: 1,
                        alignSelf: 'stretch'
                    }}>
                        {showEducation ? <EducationProfile setSave={setSave}/> : <MainProfile setSave={setSave}/>}
                    </ScrollView>
                    <ArcoBrasa rootStyles={{position: 'absolute', left: 15, right: 15, bottom: 0, top: 250,}}/>
                </View>
            </View>
        </>
    )
}