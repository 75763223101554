import { useFocusEffect } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import LottieView from 'lottie-react-native';
import React, {  useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Animated, Easing, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { BookComponentsProps, BookPageProps } from '.';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

interface IPageProps<T> extends BookComponentsProps<T>, BookPageProps<T> {
  isBookOpened: boolean;
}


function Page<T>({rotateYValue, zIndex, hasPreviousPage, hasNextPage, renderContent, onNextPageClick, onPreviousPageClick,  baseStyles}: IPageProps<T>) {
  const renderPageContent = useCallback(() => {
    return (
      <>
      {renderContent()}
      {hasNextPage && (
        <TouchableOpacity onPress={onNextPageClick} style={{justifyContent: 'center', position: 'absolute', bottom: 8, right: 10}}>
          <MaterialCommunityIcons name="arrow-right-bottom" size={40} color='rgba(0, 0, 0, 0.8)'/>
        </TouchableOpacity>
      )}
      {hasPreviousPage && (
        <TouchableOpacity onPress={onPreviousPageClick} style={{justifyContent: 'center', position: 'absolute', bottom: 8, left: 55}}>
          <MaterialCommunityIcons name="arrow-left-bottom" size={40} color='rgba(0, 0, 0, 0.8)'/>
        </TouchableOpacity>
      )}
      </>
    )

  }, [renderContent, hasNextPage]);

  const rotY = rotateYValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-180deg']
  });

  const contentOpacity = rotateYValue.interpolate({
    inputRange: [0.5, 0.5],
    outputRange: [1, 0]
  });
  
  return (
    <View style={{zIndex: zIndex}}>
      <Animated.View pointerEvents={"box-none"} style={[baseStyles.root, {zIndex: zIndex, height: 690, width: 812, marginLeft: -406, marginTop: -690, transform: [{perspective: 3200}, {rotateY: rotY}]}]}>
        <View style={[baseStyles.page_base, {marginLeft: 406}]}>
          <LinearGradient
            colors={['#e0b689', '#eccda9', '#f6e7d2']}
            start={{x: 0.0, y: 0.5}}
            end={{x: 1.0, y: 0.5}}
            style={{height: 674}}
          >
              <Animated.View style={[styles.list_container, {opacity: contentOpacity, flex: 1}]}>
                {
                    renderContent && renderPageContent()
                }
              </Animated.View>
          </LinearGradient>
        </View>
      </Animated.View>
    </View>
  );
}

export default Page;

const styles = StyleSheet.create({
  page_anim: {
    ...StyleSheet.absoluteFillObject,
    top: -106,
    left: -449,
    height: 900,
    width: 900
  },
  header1: {
    fontSize: 42,
    color: 'rgba(255, 255, 255, 0.9)',
    alignSelf: 'center',
    marginTop: 120,
  },
  list_container: {
    flex: 1,
  },
});