import React, { useMemo } from 'react';
import { FlatList, StyleSheet, View, Text, Image, TouchableWithoutFeedback, TouchableOpacity } from 'react-native';
import useImage  from '../../../hooks/image.js';


const getRandomNum = (min, max) => {
  return (Math.random() * (max - min) + min);
}

const ScrapbookPage = ({items, renderItem, maxStickerNum = 12, rows = 4}) => {

  const sectWidth = 386 / (maxStickerNum / rows);
  const secHeight = 654 / rows;
  const stickerSize = maxStickerNum * 10;
  const { image: scratchPaper } = useImage('ScratchPage.png')

  const data = useMemo(() => {
    const array = new Array(maxStickerNum).fill(false);
    let currItem = 0;
    while(currItem < items.length) {
      const idx = Math.floor(Math.random()*array.length);
      if(!array[idx] && items[currItem]) {
        array[idx] = items[currItem];
        currItem++;
      }
    }
    return array;
  }, [items]);

  const randPos = useMemo(() => {
    let array = new Array;
    for(let i = 0; i < maxStickerNum * 2; i++) {
      array.push(i < maxStickerNum ? getRandomNum(0, (sectWidth - stickerSize)) : getRandomNum(0, (secHeight - stickerSize)));
    }
    return array
  }, [maxStickerNum]);

  const randRot = useMemo(() => {
    return [...Array(maxStickerNum)].map(_=> getRandomNum(0, 60))
  }, [maxStickerNum]);

  return <View
      style={{flex: 1}}
    >
      <Image source={scratchPaper} style={{width: 406, height: 674}}/>
      <FlatList
        key={maxStickerNum}
        data={data}
        scrollEnabled={false}
        style={{...StyleSheet.absoluteFill, padding: 10}}
        renderItem={({item, index}) => {
          if(!item) return <View style={{width: sectWidth, height: secHeight}} />;
          return <View style={[styles.center_content, {width: sectWidth, height: secHeight}]}>
            <View style={{
              position: 'absolute', 
              width: stickerSize, 
              height: stickerSize, 
              top: randPos[index + (randPos.length / 2)], 
              left: randPos[index], 
              transform: [{ rotate: `${randRot[index]}deg` }]
            }}>
              {renderItem ? renderItem(item) : <TestSticker/>}
            </View>
          </View>
        }}
        numColumns={maxStickerNum / rows}
        />
    </View>
}

export default ScrapbookPage;

const styles = StyleSheet.create({
  center_content: {
    justifyContent: 'center',
    alignItems: 'center'
  },
});