import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";

const HeaderRight = ({ onPress }) => (
  <TouchableOpacity onPress={onPress} style={styles.button}>
    <Ionicons name="menu" size={30} color="black" />
  </TouchableOpacity>
);

export default HeaderRight;

const styles = StyleSheet.create({
  button: {
    position: "absolute",
    top: 25,
    right: 20,
    zIndex: 1000,
    backgroundColor: "transparent",
  },
});
