import React, { useState } from 'react';
import { View, StyleSheet, ImageBackground, TouchableOpacity, Image, Text, ScrollView, Linking } from 'react-native';
import LatoText from '../../components/LatoText';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import HeaderRight from '../../components/HeaderRight';
import SanduicheMenu from '../sanduicheMenu/SanduicheMenu';
import BackToMenu from "../../components/BackToMenu";
import useParceiros from '../../hooks/parceiros';
import Loading from '../../hooks/loading';
import useImage from '../../hooks/image';

const Partners = ({ onMenuItemPress, onClose }) => {
  const { loading: bgL, error: bgE, image: bg } = useImage('bg.png');
  const [menuVisible, setMenuVisible] = useState(false);
  const navigation = useNavigation();
  const { parceirosInfos, loading: parceirosInfosLoading, errors: parceirosInfosErrors } = useParceiros();

  if (bgL || parceirosInfosLoading) {
    return <Loading />;
  }

  // if (bgE || parceirosInfosErrors.length) {
  //   return <Text>Error loading data</Text>;
  // }

  const handleEmailPress = () => {
    Linking.openURL('mailto:corporativo@gobrasa.org');
  };

  return (
    <View style={styles.containerBack}>
      <HeaderRight onPress={() => setMenuVisible(true)} />
      <BackToMenu />
      <ImageBackground source={{ uri: bg }} style={styles.imageBg} imageStyle={{ resizeMode: 'cover' }}>
        <View style={styles.container}>
          <View style={styles.titulo__container}>
            <LatoText style={styles.titulo}>Parceiros</LatoText>
            <View>
              <LatoText style={styles.titulo__descricao}>Apoie a BRASA e faça parte de uma rede que</LatoText>
              <LatoText style={styles.titulo__descricao}>impulsiona o futuro de jovens brasileiros.</LatoText>
            </View>
            <TouchableOpacity style={styles.titulo__botao} onPress={handleEmailPress}>
              <LatoText style={styles.titulo__botaoTexto}>Torne-se um Parceiro</LatoText>
            </TouchableOpacity>
          </View>

          <LatoText style={styles.todos}>Todos os Parceiros</LatoText>

          <View style={styles.scrollContainer}>
            <ScrollView contentContainerStyle={styles.parceiro__container} showsVerticalScrollIndicator={false}>
              {parceirosInfos.map((parceiroImage, index) => (
                <View key={index} style={styles.parceiro__conheca}>
                  {parceiroImage ? (
                    <Image source={{ uri: parceiroImage.imagem }} style={styles.parceiro__imagem} resizeMode="contain" />
                  ) : (
                    <Loading />
                  )}
                  <TouchableOpacity
                    style={styles.parceiro__botao}
                    onPress={() => navigation.navigate('Company', { parceiroChave: parceiroImage.chave })}
                  >
                    <LatoText style={styles.parceiro__conhecaTexto}>Conheça nossas oportunidades</LatoText>
                    <Ionicons name="arrow-forward" size={20} color="#005248" />
                  </TouchableOpacity>
                </View>
              ))}
            </ScrollView>
          </View>
        </View>
      </ImageBackground>
      {menuVisible && <SanduicheMenu onMenuItemPress={(screen) => {
        setMenuVisible(false);
        navigation.navigate(screen);
      }} onClose={() => setMenuVisible(false)} />}
    </View>
  );
}

export default Partners;

const styles = StyleSheet.create({
  containerBack: {
    flex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  imageBg: {
    flex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 60,
    paddingBottom: 40
  },
  scrollContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titulo__container: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: 10,
    marginBottom: 30
  },
  titulo: {
    color: '#005248',
    fontSize: 24,
    fontWeight: 900,
  },
  titulo__descricao: {
    color: '#005248',
    fontSize: 14,
    fontWeight: 600,
  },
  titulo__botao: {
    backgroundColor: '#049C5C',
    borderRadius: 30,
    width: 173,
    height: 33,
    alignItems: 'center',
    padding: 10,
    marginTop: 10,
    justifyContent: 'center'
  },
  titulo__botaoTexto: {
    color: '#FFF9E3',
    fontSize: 14,
  },
  todos: {
    color: '#005248',
    fontSize: 16,
    fontWeight: '900',
    marginTop: 20,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
  parceiro__container: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  parceiro__conheca: {
    backgroundColor: '#FFFCE7',
    padding: 10,
    borderRadius: 30,
    alignItems: 'center',
    width: 327,
    height: 195,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingLeft: 20,
    gap: 10,
    marginBottom: 20,
  },
  parceiro__imagem: {
    height: 100,
    width: 200,
  },
  parceiro__botao: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  parceiro__conhecaTexto: {
    color: '#005248',
    marginRight: 5,
    fontWeight: 900
  }
});
