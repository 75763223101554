import React, { useEffect, useState } from "react";
import {
  FlatList,
  ImageBackground,
  SafeAreaView,
  SectionList,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import getSchedule from "../conferences/schedules";
import { useConference } from "../hooks/conference";
import useGeneralizeColor from "../hooks/generalizeColor";
import * as Animatable from "react-native-animatable";
import {
  ISchedule,
  ScheduleTime,
  ConfEventColors,
} from "../interfaces/ISchedule";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import IInitiative from "../interfaces/IInitiative";
import LatoText from "./LatoText";
import colors from "../util/colors";
import useImage from "../hooks/image";
import { sumBy } from "lodash";
import BackToMenu from "./BackToMenu";
import HeaderRight from "./HeaderRight";
import SanduicheMenu from "../pages/sanduicheMenu/SanduicheMenu";

const onClick = (navigation: any, initiative: IInitiative) => {
  navigation.navigate("InitiativesBoard", {
    autoOpenInitiative: {
      initiative: initiative,
    } as { initiative: IInitiative },
  });
};

const renderTime = (
  navigation: NavigationProp<any>,
  time: ScheduleTime,
  conferenceColors: ConfEventColors
) => {
  return (
    <View
      key={`${time.time}-${time.events.length}`}
      style={styles.itemContainer}
    >
      <View style={styles.timeContainer}>
        <LatoText style={[styles.eventTime]} bold>
          {time.time}
        </LatoText>
      </View>
      <View style={styles.events}>
        {time.events.map((event, index) => {
          if (event.initiative) {
            return (
              <TouchableOpacity
                style={[styles.eventButton, { marginBottom: 8 }]}
                key={event.name}
                onPress={() => onClick(navigation, event.initiative)}
              >
                <LatoText
                  style={[
                    styles.eventName,
                    { color: conferenceColors?.eventWithLink },
                  ]}
                >
                  {event.name}
                </LatoText>
              </TouchableOpacity>
            );
          }
          return (
            <LatoText
              key={event.name}
              style={[
                styles.eventName,
                { color: conferenceColors?.eventNoLink, marginBottom: 8 },
              ]}
            >
              {event.name}
            </LatoText>
          );
        })}
      </View>
    </View>
  );
};

const Schedule: React.FC<any> = () => {
  const [selectedDay, setSelectedDay] = useState(0);
  const { currentSelectedConferenceId } = useConference();
  const [schedule, setSchedule] = useState([]);
  const navigation = useNavigation();
  const { conferenceColors } = useGeneralizeColor();
  const [menuVisible, setMenuVisible] = useState(false);
  const { loading: bgL, error: bgE, image: bg } = useImage("bg.png");
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {
    if (currentSelectedConferenceId) {
      getSchedule(currentSelectedConferenceId).then((res) => setSchedule(res));
    }
  }, [currentSelectedConferenceId]);

  return (
    <View style={{ flex: 1 }}>
      <ImageBackground source={bg} style={styles.container}>
        <BackToMenu />
        <HeaderRight onPress={() => setMenuVisible(true)} />
        <SafeAreaView style={{ flex: 0 }} />
        <StatusBar backgroundColor="#fff" barStyle="light-content" />
        <View style={styles.header}>
          <LatoText style={styles.title}>Cronograma</LatoText>
          <View style={styles.daySelectionContainer}>
            {schedule.length > 1
              ? schedule.map((day, index: number) => (
                  <TouchableOpacity
                    key={day.day}
                    style={[
                      styles.daySelectionButton,
                      index !== selectedDay
                        ? styles.daySelectionButtonDisabled
                        : {},
                      index > 0 && { marginLeft: 32 },
                    ]}
                    onPress={() => setSelectedDay(index)}
                  >
                    <LatoText style={styles.day}>{day.day}</LatoText>
                    <LatoText style={styles.month}>
                      {months[day.month]}
                    </LatoText>
                  </TouchableOpacity>
                ))
              : null}
          </View>
        </View>
        <Animatable.View style={[styles.content]} animation="fadeInUpBig">
          <FlatList
            data={schedule[selectedDay] ? schedule[selectedDay].times : []}
            renderItem={({ item }) =>
              renderTime(navigation, item, conferenceColors)
            }
            keyExtractor={(item, index) => `${item.time}-${index}`}
            showsVerticalScrollIndicator={false}
          />
        </Animatable.View>
      </ImageBackground>
      {menuVisible && (
        <SanduicheMenu
          onMenuItemPress={(screen: never) => {
            setMenuVisible(false);
            navigation.navigate(screen);
          }}
          onClose={() => setMenuVisible(false)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    alignItems: "center",
    marginBottom: 32,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
    fontSize: 32,
    fontWeight: "bold",
    color: "#005248",
  },
  daySelectionContainer: {
    flexDirection: "row",
    marginTop: 32,
  },
  daySelectionButton: {
    backgroundColor: colors.blue,
    height: 80,
    width: 80,
    borderRadius: 40,
    justifyContent: "center",
    alignItems: "center",
  },
  daySelectionButtonDisabled: {
    backgroundColor: "transparent",
    borderWidth: 2,
    borderColor: "white",
  },
  day: {
    fontWeight: "bold",
    color: "#fff",
    fontSize: 24,
  },
  month: {
    fontWeight: "bold",
    color: "#fff",
  },
  content: {
    flex: 2,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 30,
  },
  itemContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 24,
  },
  timeContainer: {
    marginTop: 4,
    width: "auto",
  },
  events: {
    flex: 1,
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  eventName: {
    fontSize: 22,
    marginLeft: 8,
    flexWrap: "wrap",
  },
  eventButton: {
    alignItems: "flex-start",
  },
  eventTime: {
    color: "#000",
    fontSize: 18,
  },
});

export default Schedule;
