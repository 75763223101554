import { useState, useEffect } from 'react';
import useImage from './image';

const useParceiros = () => {
  const [parceirosInfos, setparceirosInfos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  const { image: btgParceiros, loading: btgParceirosLoading, error: btgParceirosError } = useImage('btgParceiros.png');
  const { image: stoneParceiros, loading: stoneParceirosLoading, error: stoneParceirosError } = useImage('stoneParceiros.png');
  const { image: ambevParceiros, loading: ambevParceirosLoading, error: ambevParceirosError } = useImage('ambevParceiros.png');
  const { image: bainParceiros, loading: bainParceirosLoading, error: bainParceirosError } = useImage('bainParceiros.png');
  const { image: fundacaoParceiros, loading: fundacaoParceirosLoading, error: fundacaoParceirosError } = useImage('fundacaoParceiros.png');
  const { image: brParceiros, loading: brParceirosLoading, error: brParceirosError } = useImage('brParceiros.png');
  const { image: itauParceiros, loading: itauParceirosLoading, error: itauParceirosError } = useImage('itauParceiros.png');
  const { image: capilanoParceiros, loading: capilanoParceirosLoading, error: capilanoParceirosError } = useImage('capilanoParceiros.png');

  const { image: btgLogo, loading: btgLogoLoading, error: btgLogoError } = useImage('btgLogo.png');
  const { image: stoneLogo, loading: stoneLogoLoading, error: stoneLogoError } = useImage('stoneLogo.png');
  const { image: ambevLogo, loading: ambevLogoLoading, error: ambevLogoError } = useImage('ambevLogo.png');
  const { image: bainLogo, loading: bainLogoLoading, error: bainLogoError } = useImage('bainLogo.png');
  const { image: fundacaoLogo, loading: fundacaoLogoLoading, error: fundacaoLogoError } = useImage('fundacaoLogo.png');
  const { image: brLogo, loading: brLogoLoading, error: brLogoError } = useImage('brLogo.png');
  const { image: itauLogo, loading: itauLogoLoading, error: itauLogoError } = useImage('itauLogo.png');
  const { image: capilanoLogo, loading: capilanoLogoLoading, error: capilanoLogoError } = useImage('capilanoLogo.png');

  useEffect(() => {
    if (!btgParceirosLoading && !stoneParceirosLoading && !ambevParceirosLoading && !bainParceirosLoading && !fundacaoParceirosLoading && !brParceirosLoading && !btgLogoLoading && !stoneLogoLoading && !ambevLogoLoading && !fundacaoLogoLoading && !brLogoLoading && !itauLogoLoading && !itauParceirosLoading && !capilanoLogoLoading && !capilanoParceirosLoading) {
      setparceirosInfos([
        { imagem: btgParceiros, chave: "btg", logo: btgLogo },
        { imagem: stoneParceiros, chave: "stone", logo: stoneLogo },
        { imagem: itauParceiros, chave: "itau", logo: itauLogo },
        { imagem: bainParceiros, chave: "bain", logo: bainLogo },
        { imagem: brParceiros, chave: "br", logo: brLogo },
        { imagem: ambevParceiros, chave: "ambev", logo: ambevLogo },
        { imagem: fundacaoParceiros, chave: "fundacao", logo: fundacaoLogo },
        { imagem: capilanoParceiros, chave: "capilano", logo: capilanoLogo },
      ]);
      setLoading(false);
      setErrors([
        btgParceirosError,
        stoneParceirosError,
        ambevParceirosError,
        bainParceirosError,
        fundacaoParceirosError,
        brParceirosError,
        btgLogoError,
        stoneLogoError,
        ambevLogoError,
        bainLogoError,
        fundacaoLogoError,
        brLogoError,
        itauLogoError,
        itauParceirosError,
        capilanoLogoError,
        capilanoParceirosError
      ].filter(error => error));
    }
  }, [
    btgParceirosLoading,
    stoneParceirosLoading,
    ambevParceirosLoading,
    fundacaoParceirosLoading,
    brParceirosLoading,
    btgLogoLoading,
    stoneLogoLoading,
    ambevLogoLoading,
    fundacaoLogoLoading,
    brLogoLoading,
    btgParceiros,
    stoneParceiros,
    ambevParceiros,
    bainParceiros,
    fundacaoParceiros,
    brParceiros,
    btgLogo,
    stoneLogo,
    ambevLogo,
    fundacaoLogo,
    brLogo,
    bainLogoLoading,
    bainParceirosLoading,
    itauLogoLoading,
    itauParceirosLoading,
    capilanoLogoLoading,
    capilanoParceirosLoading
  ]);

  return { parceirosInfos, loading, errors };
};

export default useParceiros;
