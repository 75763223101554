import React, { useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { useConference } from '../../../hooks/conference';
import { getSchedule } from '../../../services/scheduleServices';
import { getAllInitiatives } from '../../../services/initiativesService';
import { registerToInitiative, getParticipantsRegisteredToInitiative, getRegisteredInitiatives } from '../../../services/initiativesService';
import LatoText from "../../../components/LatoText";
import Loading from '../../../hooks/loading';
import SubscriptionModal from './SubscriptionModal';

const AllInitiatives = () => {
  const { currentSelectedConference, currentSelectedConferenceId, participantId } = useConference();
  const [allInitiatives, setAllInitiatives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userInitiatives, setUserInitiatives] = useState([]);
  const [subscriptionUpdate, setSubscriptionUpdate] = useState(false);
  const [subscribingId, setSubscribingId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalColor, setModalColor] = useState("");

  useFocusEffect(
    useCallback(() => {
      async function fetch() {
        if (currentSelectedConference && currentSelectedConferenceId) {
          try {
            const resParticipantInitiatives = await getRegisteredInitiatives(participantId);
            const resAllInitiatives = await getAllInitiatives(currentSelectedConference);
            const resSchedule = await getSchedule(currentSelectedConferenceId);

            const initiativesMap = new Map(resAllInitiatives.data.initiatives.map(i => [i.id, { type: i.type, spotsAvailable: i.spotsAvailable }]));

            const userInitiativesIds = resParticipantInitiatives.data.initiatives[0].participantInitiatives
              .map((init) => init.initiativeId);

            const allInitiativesWithTime = [];

            resSchedule.data.slice(0, 2).forEach(day => {
              day.times.forEach(time => {
                time.events.forEach(event => {
                  if (event.initiative && userInitiativesIds.includes(event.initiative.id)) {
                    allInitiativesWithTime.push({
                      name: event.initiative.name,
                      time: time.time,
                      day: day.day
                    });
                  }
                });
              });
            });

            setUserInitiatives(allInitiativesWithTime);

            const days = await Promise.all(resSchedule.data.map(async (day, index) => {
              const initiatives = await Promise.all(day.times.map(async time => {
                const events = await Promise.all(time.events.map(async event => {
                  const initiativeData = initiativesMap.get(event.initiative?.id);
                  if (initiativeData && initiativeData.type === 'initiative') {
                    const participantsPerInitiatives = await getParticipantsRegisteredToInitiative(event.initiative?.id);
                    const filledSpots = participantsPerInitiatives.data.participants.length;
                    let remainingSpots; 
                    if (initiativeData.spotsAvailable > 0) {
                      remainingSpots = initiativeData.spotsAvailable - filledSpots;
                    } else if (initiativeData.spotsAvailable == 0) {
                      remainingSpots = 0;
                    }
                    return {
                      id: event.initiative.id,
                      name: event.initiative.name,
                      time: time.time,
                      spotsAvailable: remainingSpots
                    };
                  }
                }));
                return events.filter(event => event !== undefined);
              }));

              return {
                day: day.day,
                initiatives: initiatives.flat()
              };
            }));

            setAllInitiatives(days);
          } catch (error) {
            console.log("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        } else {
          console.log("Conference or Conference ID not set.");
          setLoading(false);
        }
      }
      fetch();
    }, [subscriptionUpdate, currentSelectedConference, currentSelectedConferenceId])
  );

  const individualInitiativesSet = new Set(userInitiatives.map(i => i.name));

  const timesSet = new Set(userInitiatives.map(i => `${i.time}-${i.day}`));

  const handleSubscribe = async (initiativeId, time, day, remainingSpots) => {
    try {
      const timeDayKey = `${time}-${day}`;
      if (timesSet.has(timeDayKey)) {
        setModalMessage("Não foi possível inscrever-se nessa iniciativa. Você já está inscrito em outra iniciativa neste mesmo horário e dia.");
        setModalColor("#CF364C");
        setIsOpen(true);
      } else if (remainingSpots > 0) {
        const result = await registerToInitiative(initiativeId, participantId);
        setSubscribingId(initiativeId);
        setSubscriptionUpdate(prev => !prev);
        setModalMessage('Inscrição concluída com sucesso.');
        setModalColor("#049C5C");
        setIsOpen(true);
        if (result.success) {
          const newInitiative = allInitiatives
            .flatMap(day => day.initiatives)
            .find(initiative => initiative.id === initiativeId);
          if (newInitiative) {
            onSubscribeSuccess(newInitiative);
          }
        }
      } else {
        setModalMessage('Não é possível se inscrever nessa iniciativa.');
        setModalColor("#CF364C");
        setIsOpen(true);
      }
    } catch (error) {
      setModalMessage('Erro ao tentar se inscrever.');
      setModalColor("#CF364C");
      setIsOpen(true);
      console.log("Erro ao inscrever:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <View>
      {allInitiatives.map((day) => (
        <View key={day.day}>
          <LatoText style={styles.dayHeader}>Dia {day.day}</LatoText>
          {day.initiatives.map((item, index) => (
            <View key={index} >
              <View style={styles.initiativeContainer}>
                <View style={styles.initiativeTitle}>
                  <LatoText style={styles.initiativeName}>{item.name}</LatoText>
                  <View style={styles.initiativeDetails}>
                    <LatoText style={styles.initiativeTime}>{item.time}</LatoText>
                    {!individualInitiativesSet.has(item.name) && (
                      <LatoText style={styles.initiativeSpots}>{item.spotsAvailable} vagas disponíveis</LatoText>
                    )}
                  </View>
                </View>
                {!individualInitiativesSet.has(item.name) && (
                  subscribingId === item.id ? (
                    <Loading />
                  ) : (
                    <TouchableOpacity
                      style={styles.botaoInscrever}
                      onPress={() => handleSubscribe(item.id, item.time, day.day, item.spotsAvailable)}
                    >
                      <LatoText>Inscreva-se</LatoText>
                    </TouchableOpacity>
                  )
                )}
              </View>
            </View>
          ))}
        </View>
      ))}
      <SubscriptionModal visible={isOpen} setVisible={setIsOpen} subscriptionMessage={modalMessage} modalColor={modalColor}/>
    </View>
  );
};

const styles = StyleSheet.create({
  dayHeader: {
    fontSize: 14,
    color: '#005248',
    fontWeight: 900,
    paddingVertical: 10,
    paddingHorizontal: 5,
    textAlign: 'center'
  },
  initiativeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 18,
  },
  initiativeTitle: {
    flexDirection: 'column',
    flex: 1,
    flexWrap: 'wrap',
  },
  initiativeName: {
    color: '#005248',
    fontSize: 14,
    flex: 1,
    flexShrink: 1,
  },
  initiativeDetails: {
    fontSize: 12,
    flexDirection: 'row'
  },
  initiativeTime: {
    color: '#005248',
    marginRight: 10,
  },
  initiativeSpots: {
    color: '#FF7878'
  },
  botaoInscrever: {
    backgroundColor: '#FF7878',
    padding: 10,
    borderRadius: 30,
    width: 117,
    textAlign: 'center',
    justifyContent: 'center',
    color: '#FFF9E3',
    fontSize: 12,
    marginLeft: 10,
    height: 21,
    width: 117,
  }
});

export default AllInitiatives;
