import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; 
import colors from '../util/colors';

interface Props {
  defaultRating?: number;
  maxRating: number;
  onChange: (rating: number) => void;
}

const RatingBar: React.FC<Props> = ({defaultRating, maxRating, onChange}) => {
  const [rating, setRating] = React.useState(defaultRating || null);

  const onChangeEvent = (index) => {
    setRating(index);
    if(onChange) {
      onChange(index);
    }
  }
  return (<View style={{flexDirection: 'row',  alignItems: 'center', justifyContent: 'center', padding: 32}}>
    {Array.from({length: maxRating}, (_, index) => {
      return (
      <TouchableOpacity  key={`star-${index}`} onPress={() => onChangeEvent(index + 1)}>
        <MaterialIcons name={index < rating ? "star" :"star-border"} size={60} color={colors.darkerBlue}/>
      </TouchableOpacity>)
    })}
  </View>);
}

export default RatingBar;