import React, { useCallback, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { useConference } from "../../../hooks/conference";
import { unregisterFromInitiative, getRegisteredInitiatives } from "../../../services/initiativesService";
import { getSchedule } from "../../../services/scheduleServices";
import LatoText from "../../../components/LatoText";
import Loading from "../../../hooks/loading";
import SubscriptionModal from './SubscriptionModal';

const UserInitiatives = ({ reloadTrigger }) => {
  const {
    currentSelectedConference,
    currentSelectedConferenceId,
    participantId,
  } = useConference();
  const [userInitiatives, setUserInitiatives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonVisibility, setButtonVisibility] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalColor, setModalColor] = useState("");

  const fetchData = async () => {
    if (currentSelectedConference && currentSelectedConferenceId) {
      try {
        const resParticipantInitiatives = await getRegisteredInitiatives(participantId);
        const resSchedule = await getSchedule(currentSelectedConferenceId);

        const userInitiativesIds = resParticipantInitiatives.data.initiatives[0].participantInitiatives
          .map((init) => init.initiativeId);

        const allInitiativesWithTime = [];

        resSchedule.data.slice(0, 2).forEach((day) => {
          day.times.forEach((time) => {
            time.events.forEach((event) => {
              if (
                event.initiative &&
                userInitiativesIds.includes(event.initiative.id)
              ) {
                allInitiativesWithTime.push({
                  id: event.initiative.id,
                  name: event.initiative.name,
                  time: time.time,
                  day: day.day,
                });
              }
            });
          });
        });

        // estado de visibilidade do botão
        const initialButtonVisibility = {};
        allInitiativesWithTime.forEach((initiative) => {
          initialButtonVisibility[initiative.id] = true;
        });

        setButtonVisibility(initialButtonVisibility);
        setUserInitiatives(allInitiativesWithTime);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    } else {
      console.log("Conference or Conference ID not set.");
      setLoading(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchData();
    }, [currentSelectedConference, currentSelectedConferenceId, reloadTrigger])
  );

  const handleUnsubscribe = async (initiativeId) => {
    try {
      await unregisterFromInitiative(initiativeId, participantId);
      setButtonVisibility((prevState) => ({
        ...prevState,
        [initiativeId]: false,
      }));
      setModalMessage("Cancelamento concluído.");
      setModalColor("#CF364C")
      setIsOpen(true);
    } catch (error) {
      console.log("Error unsubscribing from initiative:", error);
    }
  };

  // Agrupar iniciativas por dia
  const groupedInitiatives = userInitiatives.reduce((acc, initiative) => {
    const day = `Dia ${initiative.day}`;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(initiative);
    return acc;
  }, {});

  if (loading) {
    return <Loading />;
  }

  return (
    <View style={styles.container}>
      {userInitiatives.length > 0 && (
        Object.keys(groupedInitiatives).map((day) => (
          <View key={day}>
            <LatoText style={styles.dayHeader}>{day}</LatoText>
            {groupedInitiatives[day].map((item, index) => (
              <View key={index} style={styles.initiativeContainer}>
                {buttonVisibility[item.id] && (
                  <>
                    <View style={styles.initiativeTitle}>
                      <LatoText style={styles.initiativeName}>
                        {item.name}
                      </LatoText>
                      <LatoText style={styles.initiativeTime}>
                        {item.time}
                      </LatoText>
                    </View>
                    <TouchableOpacity
                      style={styles.unsubscribeButton}
                      onPress={() => handleUnsubscribe(item.id)}
                    >
                      <LatoText style={styles.unsubscribeButtonText}>
                        Cancelar Inscrição
                      </LatoText>
                    </TouchableOpacity>
                  </>
                )}
              </View>
            ))}
          </View>
        ))
      )}
      <SubscriptionModal visible={isOpen} setVisible={setIsOpen} subscriptionMessage={modalMessage} modalColor={modalColor} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  dayHeader: {
    fontSize: 14,
    fontWeight: 900,
    marginTop: 15,
    marginBottom: 15,
    color: "#005248",
    textAlign: "center",
  },
  initiativeContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  initiativeTitle: {
    flexDirection: "column",
    flex: 1,
    flexWrap: "wrap",
  },
  initiativeName: {
    color: "#005248",
    fontSize: 14,
    flex: 1,
    flexShrink: 1,
  },
  initiativeTime: {
    color: "#005248",
    fontSize: 12,
    marginRight: 10,
  },
  unsubscribeButton: {
    backgroundColor: "#FF7878",
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 30,
    marginLeft: 16,
    height: 21,
    width: 117,
    textAlign: "center",
    justifyContent: "center",
  },
  unsubscribeButtonText: {
    color: "#FFF9E3",
    fontSize: 12,
    flexShrink: 0,
  }
});

export default UserInitiatives;
