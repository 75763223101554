import React, { useCallback, useState } from 'react';
import { useFocusEffect,  useRoute } from '@react-navigation/native';
import { View, Text, Dimensions, StyleSheet, TouchableWithoutFeedback, ImageBackground } from 'react-native';
import { Button, TextInput } from 'react-native-paper';
import { useConference } from '../../hooks/conference';
import IInitiative from '../../interfaces/IInitiative';
import { getParticipantInitiatives, sendQuestion } from '../../services/initiativesService';
import DropDownPicker, { ItemType } from 'react-native-dropdown-picker';
import summitBg from '../../assets/summitBg.png';
import { StatusBar } from 'expo-status-bar';
import LatoText from '../../components/LatoText';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '../../util/colors';
import HeaderRight from '../../components/HeaderRight';
import SanduicheMenu from '../sanduicheMenu/SanduicheMenu';
import { useNavigation } from '@react-navigation/native';

import useImage  from '../../hooks/image.js';
type InitiativeWithRating = {
  rating?: number,
  initiative: IInitiative,
};

const QuestionPage = () => {
    const [text, setText] = useState('');
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const {currentSelectedConference} = useConference();
    const [initiatives, setInitiatives] = React.useState<ItemType<IInitiative>[]>([]);
    const [invalid, setInvalid] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const navigation = useNavigation();
    const { loading: bgL, error: bgE, image: bg } = useImage('bg.png')
    useFocusEffect(useCallback(() => {
        async function fetch() {
          try {
            const response = await getParticipantInitiatives(currentSelectedConference);
            setInitiatives(response.data.initiatives.filter(
              (initiative: InitiativeWithRating) => initiative.initiative.allowQuestions
            ).map((initiative: InitiativeWithRating) => ({label: initiative.initiative.name, value: initiative.initiative.name})))
          } catch (error) {
            console.log(error);
          }
        }
        if(currentSelectedConference) {
          fetch();
        }
      }, []));

    const submitQuestion = async () => {
      if (text.length <= 10) {
        setInvalid(true);
      }
      if (text.length > 10 && value != null) {
        setInvalid(false);
        try {
          setLoading(true);
          await sendQuestion(currentSelectedConference, value, text);
          setText('');
          setValue(null);
        } catch (error) {
          setInvalid(true);
        } finally {
          setLoading(false);
      }
    };
  };

    return (
      <ImageBackground source={bg} style={{ flex: 1, padding: 20 }}>
      <HeaderRight onPress={() => setMenuVisible(true)} />
      <StatusBar />
      {!menuVisible && (
        <>
          <View style={{ flexDirection: 'row', marginBottom: 32, alignItems: 'center' }}>
            <MaterialIcons name="question-answer" color={"#005248"} size={27} />
            <LatoText bold style={{ marginLeft: 8, color: '#005248', fontSize: 32 }}>Faça uma pergunta</LatoText>
          </View>
          <DropDownPicker
            style={{ marginBottom: 32, borderColor: colors.darkerBlue, borderWidth: 2 }}
            open={open}
            value={value}
            items={initiatives}
            setOpen={setOpen}
            setValue={setValue}
            setItems={setInitiatives}
            placeholder="Escolha um painel"
            translation={{ NOTHING_TO_SHOW: 'Seleção vazia' }}
          />
          {initiatives.length === 0 && (
            <LatoText bold style={{ color: '#005248', fontSize: 24, marginTop: 32, alignItems: 'center' }}>
              Leia o QRCode do painel para que você possa fazer uma pergunta
            </LatoText>
          )}
          {value && (
            <>
              <TextInput
                activeUnderlineColor={colors.darkerBlue}
                mode='flat'
                label={"Digite sua pergunta"}
                error={invalid}
                value={text}
                multiline
                onChangeText={newText => setText(newText)}
                style={{
                  margin: 4, flex: 1, height: '100%', backgroundColor: 'white',
                  borderWidth: 3, borderColor: colors.darkerBlue, borderRadius: 5, padding: 10
                }}
              />
              <Button
                color={colors.blue}
                mode="contained"
                disabled={loading || text.length <= 10}
                style={{ alignSelf: "stretch", marginTop: 16 }}
                onPress={submitQuestion}
              >
                {loading ? "Enviando..." : "Enviar Pergunta"}
              </Button>
              {invalid && (
                <View style={{ alignSelf: "stretch", marginTop: 16, backgroundColor: 'darkred' }}>
                  <LatoText style={{ color: "white" }}>
                    Erro ao enviar a pergunta, tente mais tarde
                  </LatoText>
                </View>
              )}
            </>
          )}
        </>
      )}
      {menuVisible && (
        <SanduicheMenu
          onMenuItemPress={(screen: never) => {
            setMenuVisible(false);
            navigation.navigate(screen);
          }}
          onClose={() => setMenuVisible(false)}
        />
      )}
    </ImageBackground>
    )
}

export default QuestionPage;