import React, { useEffect, useMemo, useState } from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Ionicons, FontAwesome, MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { StyleSheet, View } from "react-native";
import "../components/style/modal.css"
import ConferencesRouter from './ConferencesRouter';
import ProfileRouter from './ProfileRouter';
import ConferencesMenu from '../pages/conferences/ConferencesMenu';
import ContactsBook from '../pages/contacts/ContactsBook';
import Schedule from '../components/Schedule'
import { useConference } from '../hooks/conference'
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import InitiativesBook from '../pages/initiatives/InitiativesBook';
import InitiativesBoard from '../pages/initiatives/InitiativesBoard';
import QuestionPage from '../pages/questions/QuestionPage';
import * as Linking from 'expo-linking';
import { useConnections } from '../hooks/connections';
import { connectToInitiative, getInitiativeById } from '../services/initiativesService';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { FAB, TextInput, Button } from "react-native-paper"
import colors from '../util/colors';
import PendingConnectionPage from '../pages/pendingConnection/PendingConnectionPage';
import { IconButton } from 'react-native-paper';
import api from '../util/axios';
import { useAuth } from '../hooks/auth';
import PBModal from '../components/PBModal';
import Leaderboard from '../pages/leaderboard/Leaderboard';
import { getParticipantById, scanQRCode } from '../services/participantServices';
import SanduicheMenu from '../pages/sanduicheMenu/SanduicheMenu';
import Partners from '../pages/partners/Partners'
import Company from '../pages/partners/Company'

const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

export default function MainRouter() {
  const { currentSelectedConference, selectConference, conferences, deselectConference } = useConference();
  const navigation = useNavigation();
  const url = Linking.useURL();
  const [parsingInitiative, setParsingInitiative] = useState(false);
  const { connect, loading, socketClient } = useConnections();
  const [isrecruiter, setisRecruiter] = useState(true);
  const [ispressed, setisPressed] = useState(false);
  const [chatId, setChatId] = useState('');
  const { user } = useAuth();
  const [menuVisible, setMenuVisible] = useState(false);

  const conferenceName = useMemo(() => {
    return conferences.find(conference => conference.slug === currentSelectedConference)?.name;
  }, [currentSelectedConference]);

  useEffect(() => {
    async function beginConnection() {
      if (url) {
        const parsed = Linking.parse(url);
        if (!loading && socketClient && parsed.path === 'connect' && parsed.queryParams.participantId) {

          // find conference slug with participant id
          let participant = await getParticipantById(parsed.queryParams.participantId);
          await selectConference(participant.data.participant.conference.slug);

          connect(parsed.queryParams.participantId);
        }
      }
    }
    beginConnection();
  }, [url, currentSelectedConference, loading, socketClient]);


  useEffect(() => {
    async function connectInitiative() {
      if (url) {
        const parsed = Linking.parse(url);
        if (parsed.path === 'initiative' && parsed.queryParams.initiativeId && !parsingInitiative) {
          setParsingInitiative(true);
          let slug;
          if (currentSelectedConference == null) {
            // find conferece slug with initiative id
            let initiative = await getInitiativeById(parsed.queryParams.initiativeId);
            slug = initiative.data.initiative.conference.slug;
            await selectConference(slug);
          } else {
            slug = currentSelectedConference;
          }
          connectToInitiative(slug, parsed.queryParams.initiativeId)
            .then((data) => {
              navigation.navigate('InitiativesBoard', { autoOpenInitiative: data.data.initiativeLink });
              window.history.replaceState(null, "BRASA", "/")
            }).catch(err => console.error(err))
            .finally(() => setParsingInitiative(false));
        }
      }
    }
    connectInitiative();
  }, [url, currentSelectedConference, socketClient, loading]);

  useEffect(() => {
    async function scanQRCodeF() {
      if (url) {
        const parsed = Linking.parse(url);
        if (parsed.path === 'explorer' && parsed.queryParams.participantId && parsed.queryParams.qrCodeId) {
          const participantId = parsed.queryParams.participantId;
          const qrCodeId = parsed.queryParams.qrCodeId;
          try {
            await scanQRCode(participantId, qrCodeId);

          } catch (error) {

          }
        }
      }
    }
    scanQRCodeF();
  }, [url]);

  function TabsRouter() {
    let nav = (<Tab.Navigator screenOptions={{
      headerShown: false,
      headerShadowVisible: false,
      tabBarLabel: () => null,
      tabBarActiveTintColor: "#000",
      tabBarStyle: { display: currentSelectedConference ? 'none' : 'flex' },
    }}>
      {!currentSelectedConference && <>
        <Tab.Screen name="ConferencesMenu" component={ConferencesMenu} options={{
          tabBarIcon: ({ focused, color, size }) => (<Ionicons name="home" color={color} size={32} />),
        }} />
        <Tab.Screen name="Profile" component={ProfileRouter} options={{
          tabBarIcon: ({ focused, color, size }) => (<FontAwesome name="user" color={color} size={32} />),
        }} />
      </>
      }
      {currentSelectedConference && <>
        <Tab.Screen name="Schedule" component={Schedule} options={{ tabBarButton: () => null }} />
      </>}

    </Tab.Navigator>)
    return nav;
  }

  return (<>
    <Stack.Navigator>
      {/* {!currentSelectedConference && (
            <Stack.Screen name='ConferenceMenu' component={ConferencesMenu} options={{headerShown: false}} />
        )} */}
      <Stack.Screen name="Main" component={TabsRouter} options={{ headerShown: false }} />
      <Stack.Screen name="PendingConnection" component={PendingConnectionPage} options={{ headerShown: false }} />
      <Stack.Screen name="InitiativesBook" component={InitiativesBook} options={{ headerShown: false }} />
      <Stack.Screen name="Conference" component={ConferencesRouter}
        options={{
          title: conferenceName,
          headerStyle: {
            backgroundColor: colors.darkerBlue,
          },
          headerShadowVisible: false,
          headerTintColor: '#fff',
          headerTitleStyle: {
            fontWeight: 'bold',
          },
        }}
      />
      <Stack.Screen name="ContactsBook" component={ContactsBook} options={{ headerShown: false }} />
      <Stack.Screen name="InitiativesBoard" component={InitiativesBoard} options={{ headerShown: false }} />
      <Stack.Screen name="Questions" component={QuestionPage} options={{ headerShown: false }} />
      <Stack.Screen name="Leaderboard" component={Leaderboard} options={{ headerShown: false }} />
      <Stack.Screen name="Profile" component={ProfileRouter} options={{ headerShown: false }} />
      <Stack.Screen name="Partners" component={Partners} options={{ headerShown: false }} />
      <Stack.Screen name="Company" component={Company} options={{ headerShown: false }} />
    </Stack.Navigator>
  </>)
};

export const styles = StyleSheet.create({
  modal: {
    backgroundColor: '#fff',
    borderRadius: '25px',
    boxShadow: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20%',
    position: 'relative',
    width: '50%',
  },
  header: {
    borderBottom: '1px solid #eee',
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '10px',
    padding: '5px',
    textAlign: 'left',
    width: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Verdana',
    fontSize: '14px',
    justifyContent: 'center',
    padding: '5px',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#F9F9FB',
    borderRadius: '8px',
    color: '#A9AAB9',
  },
  actions: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    padding: '8px',
    width: '100%',
  },
  close: {
    background: '#ffffff',
    border: '1px solid #cfcece',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'block',
    fontSize: '24px',
    lineHeight: '20px',
    padding: '10px 15px',
    position: 'absolute',
    right: '-10px',
    top: '-10px',
  },
  button: {
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 'bold',
    margin: '0 10px',
    padding: '13px',
    border: 'none',
    backgroundColor: 'white',
    marginRight: '14px',

  },
  buttonY: {
    backgroundColor: '#3498db',
    border: 'none',
    borderRadius: '15px',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: 'bold',
    margin: '0 10px',
    padding: '13px',
  },
  textInput: {
    fontSize: '14px',
    marginTop: '10px',
    padding: '12px',
    width: '100%',
    border: 'solid',
    borderColor: 'grey',
    outlineStyle: 'none',
    borderRadius: '8px',

  },
  box: {
    position: "fixed",
    top: "5px",
    left: "5fpx",
    zIndex: 998
  },
  tudo: {
    width: '100%',
  },
  alerta: {
    position: 'fixed',
    borderRadius: 200,
    right: 40,
    bottom: 60,
    zIndex: 999,
    backgroundColor: "white",
    cursor: "pointer",
    width: 55,
    alignItems: 'center',

  },
});