import { useFocusEffect, useRoute } from '@react-navigation/native';
import * as MailComposer from 'expo-mail-composer';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Image, ImageBackground, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useConference } from '../../hooks/conference';
import Loading from '../../hooks/loading';
import * as Linking from 'expo-linking';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import coverColors from '../../assets/cover-colors.json';
import BackToMenu from '../../components/BackToMenu';
import Book from '../../components/Book';
import NotebookPage from '../../components/Book/Pages/NotebookPage';
import LatoText from "../../components/LatoText";
import { useAuth } from "../../hooks/auth";
import useImage from '../../hooks/image.js';
import api from '../../util/axios';
import HeaderRight from '../../components/HeaderRight';
import SanduicheMenu from '../sanduicheMenu/SanduicheMenu';
import { NavigationProp, useNavigation } from '@react-navigation/native';

const lines = new Array<number>(15);

const LineDivider = () => (
  <View style={{height: 2, backgroundColor: 'rgba(0, 0, 0, 0.1)'}}/>
);

const ItemRender = ({ item }) => (
  <LatoText style={{fontSize:22}}>{item.firstName} {item.lastName}</LatoText>
);


const ContactsBook2 = () => {
  const {conferences, currentSelectedConference, participantId} = useConference();
  const route = useRoute()
  const [selectedConnection, setSelectedConnection] = useState(route.params && (route.params as any)?.autoOpenContact);
  const { image: bg, loading: bgLoading } = useImage('bg.png')
  const { image: bookCoverIMG, loading: bookCoverLoading } = useImage('BookContactsCover.png')
  const { image: brasaStamp, loading: brasaStampLoading } = useImage('BrasaStamp.png')
  const  [connections, setConnections] = useState(null);
  const [pages, setPages] = useState([]);
  const auth = useAuth();
  const [menuVisible, setMenuVisible] = useState(false);
  const navigation = useNavigation();
  
  const refreshConnections = (participantId) =>{
    if(!participantId) return;
    api.get(`/webapp/connections/${participantId}`,).then((res) => {
      const connections = res.data.connections;
      setConnections(connections);

    const pages = [];
    const chunkSize = 10;
    if(connections.length === 0) {
      setPages( [
        {
          renderContent: () => (
            <NotebookPage
                items={[]}
                key={"empty"}
                onItemClick={() => {}}
                renderItem={item => {
                  return null;
                }}  
              />
          )
        }
      ]);
      return;
    }
    for (let i = 0; i < connections.length; i += chunkSize) {
        const chunk = connections.slice(i, i + chunkSize);
        pages.push(
          {
            renderContent: () => (
                <NotebookPage
                items={chunk}
                key={i}
                onItemClick={(item) => {
                  setSelectedConnection(item);
                }}
                renderItem={item => {
                  return <ItemRender item={item} />;
                }}
              />)
        });
    }
    setPages(pages);

    }).catch((err) => {
      console.log(err);
    });
  }

  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    return age_now;
  }

  useFocusEffect(useCallback(()=> {
    refreshConnections(participantId);
    return () => {
      setSelectedConnection(null);
    }
  },[]));

  useEffect(()=> {
    refreshConnections(participantId);
  },[]);

  // TODO: Check if relevant
  // useEffect(()=> {
  //   if(participantId) {
  //     refreshConnections(participantId)
  //   }
  //   setSelectedConnection(route.params && (route.params as any)?.autoOpenContact);
  //   return () => {
  //     setSelectedConnection(null);
  //   }
  // },[route.params && (route.params as any)?.autoOpenContact]);

  if (connections === null) {
    return null
  }
  return ( 
    <View style={{
      flex: 1,
    }}>
      <BackToMenu />
      <ImageBackground source={bg} style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <HeaderRight onPress={() => setMenuVisible(true)} />
        
        {(!bgLoading && !brasaStampLoading && !bookCoverLoading) ? (
          
          <Book
          bookCover={bookCoverIMG}
          coverColors={coverColors[currentSelectedConference]["contact"]}
          openPage={
            selectedConnection ? {
              hasPreviousPage: true,
              onPreviousPageClick: () => {
                setSelectedConnection(null);
              },
              renderContent: () => {
                return <View style={[styles.item_root]}>
                  <View>
                    <FlatList
                      data={lines}
                      ItemSeparatorComponent={LineDivider}
                      renderItem={() => {
                        return <View style={styles.section}/>
                      }}
                    />
                    <View style={styles.line_margin}/>  
                  </View>

                  <View style={[styles.info_container, {position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}]}>
                    <Image source={brasaStamp} style={{width: 155, height: 161.25, marginTop: 18, transform: [{ rotate: "-13deg" }]}} />
                    <LatoText bold style={[styles.h1, {marginTop:25}]}>{`${selectedConnection.firstName.toUpperCase()} ${selectedConnection.lastName.toUpperCase()}`}</LatoText>
                    <LatoText light style={[styles.h2, {color: 'rgba(0, 0, 0, 0.8)',}]}>{`(${selectedConnection.pronouns})`}</LatoText>

                    <View style={[styles.item_container, {marginVertical: 62}]}>
                      {selectedConnection.phoneNumberCountryCode && selectedConnection.phoneNumber && (
                        <TouchableOpacity onPress={() => Platform.OS == 'web' && window.open(`https://wa.me/${selectedConnection.phoneNumberCountryCode}${selectedConnection.phoneNumber}`, '_blank')}>
                          <View style={styles.item_button}>
                            <FontAwesome name="whatsapp" size={40} color='#000000'/>
                          </View>
                        </TouchableOpacity>
                      )}
                      {selectedConnection.linkedin && (
                        <TouchableOpacity onPress={() => Linking.openURL(selectedConnection.linkedin)}>
                          <View style={styles.item_button}>
                            <FontAwesome name="linkedin" size={36} color='#000000'/>
                          </View>
                        </TouchableOpacity>
                      )}
                      {selectedConnection.resumeUrl && (
                        <TouchableOpacity onPress={() => Platform.OS == 'web' ? window.open(`${api.getUri()}/webapp/user/getCV/${selectedConnection.id}?auth=${auth.user.auth.token}`, '_blank') : {}}>
                          <View style={styles.item_button}>
                            <FontAwesome name="file-text-o" size={36} color='#000000'/>
                          </View>
                        </TouchableOpacity>
                      )}
                      {selectedConnection.email && (
                        <TouchableOpacity onPress={() => MailComposer.composeAsync({
                          recipients: [selectedConnection.email],
                          subject: `Nos conectamos na ${conferences.find(i => currentSelectedConference === i.slug).name}!`,
                        })}>
                          <View style={styles.item_button}>
                            <FontAwesome name="envelope" size={36} color='#000000'/>
                          </View>
                        </TouchableOpacity>
                      )}
                    </View>
                  
                    {selectedConnection.education &&selectedConnection.education.length !== 0 && (
                      <LatoText light style={[styles.i, {textAlign: 'center'}]}>
                        {selectedConnection.education.length !== 0 
                        ? selectedConnection.education[selectedConnection.education.length - 1]['primaryFieldOfStudy'] + 
                          (selectedConnection.education[selectedConnection.education.length - 1]['fieldOfStudy2'] ? `, ${selectedConnection.education[selectedConnection.education.length - 1]['fieldOfStudy2']}` : '')
                          + " @ " + selectedConnection.education[selectedConnection.education.length - 1]['name'] 
                        : '...'}
                      </LatoText>
                    )}
                    <LatoText light style={[styles.i]}>{`${calculate_age(selectedConnection.birthDate)} Anos`}</LatoText>
                    <LatoText light style={[styles.i]}>{`${selectedConnection.birthPlace}`}</LatoText>
                  </View>
                </View>
              },
            }: null
          }
          pages={pages}
        />
        ) : (
          <Loading/>
        )}
        
        {menuVisible && <SanduicheMenu onMenuItemPress={(screen: never) => {
            setMenuVisible(false);
            navigation.navigate(screen);
          }} onClose={() => setMenuVisible(false)} />}
      </ImageBackground>
    </View>
  );
}

export default ContactsBook2;

const styles = StyleSheet.create({
  item_root: {
    flex: 1,
  },
  info_container: {
    alignItems: 'center',
    padding: 30,
  },
  h1: {
    fontSize: 30,
    textAlign: 'center',
  },
  h2: {
    fontSize: 24,
    textAlign: 'center',
  },
  i: {
    fontSize: 22,
    marginVertical: 5
  },
  item_container: {
    width: 380,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  item_button: {
    height: 60,
    width: 60,
    borderRadius: 12,
    borderColor: '#000000',
    borderWidth: 4,
    justifyContent: 'center',
    alignItems: 'center'
  },
  section: {
    height: 43,
  },
  line_margin: {
    ...StyleSheet.absoluteFillObject,
    left: 25,
    flex: 1,
    width: 3,
    backgroundColor: 'rgba(200, 50, 0, 0.4)',
  }
});