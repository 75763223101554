
import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import LatoText from "../../../components/LatoText";
import AllInitiatives from './AllInitiatives';
import UserInitiatives from './UserInitiatives';

const InitiativesPersonal = () => {
  const [showUserInitiatives, setShowUserInitiatives] = useState(true);

  return (
    <View style={styles.container}>
      <LatoText style={styles.title}>Menu de Iniciativas</LatoText>
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={styles.toggleButton}
          onPress={() => setShowUserInitiatives(!showUserInitiatives)}
        >
          <LatoText style={styles.toggleButtonText}>
            {showUserInitiatives ? "Mostrar Todas as Iniciativas" : "Mostrar Minhas Iniciativas"}
          </LatoText>
        </TouchableOpacity>
      </View>
      <View style={styles.contentContainer}>
        <ScrollView contentContainerStyle={styles.scrollContainer}> 
          {showUserInitiatives ? (
            <UserInitiatives />
          ) : (
            <AllInitiatives />
          )}
        </ScrollView>
       </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#FFFCE7',
    width: 339,
  },
  contentContainer: {
    flex: 1,
    minHeight: 200,
    maxHeight: 350,
    overflow: 'hidden',
  },
  scrollContainer: {
    flexGrow: 1,
  },
  title: {
    color: '#005248',
    fontSize: 20,
    fontWeight: '900',
    marginBottom: 16,
    textAlign: 'left',
  },
  buttonContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  toggleButton: {
    padding: 10,
    backgroundColor: '#049C5C',
    borderRadius: 30,
    alignItems: 'center',
    width: 220,
    justifyContent: 'center',
  },
  toggleButtonText: {
    color: '#FFF9E3',
    fontSize: 14,
  }
});

export default InitiativesPersonal;
