import { useFocusEffect } from '@react-navigation/native';
import AnimatedLottieView from 'lottie-react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';
import BackCover from './BackCover';
import Cover from './Cover';
import Page from './Page';

export interface BookComponentsProps<T> {
  baseStyles: any,
  coverColors?: string[];  
}
export interface BookPageProps<T> {
  renderContent: () => React.ReactElement;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPageClick?: () => void;
  onPreviousPageClick?: () => void;
  zIndex: number;
  rotateYValue: Animated.Value;
}
type Page<T> = Omit<BookPageProps<T>, 
'defaultOpened' 
| 'hasNextPage' 
| 'onNextPageClick' 
| 'zIndex'
| 'rotateYValue'>;

interface BookProps<T>{
  openPage?: Page<T>;
  bookCover?: any;
  coverColors?: string[];  
  pages?: Page<T>[]
}

function Book<T>(props: BookProps<T>){
  const {
    coverColors,
    bookCover,
    pages,
    openPage,
  } = props;
  
  const [opened, setOpened] = useState(false);
  const [runningPrevOnIndex, setRunningPrevOnIndex] = useState(-1);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [nextRotation] = useState(new Animated.Value(0));
  const [previousRotation] = useState(new Animated.Value(1));

  const lottieAnimation = useRef();

  useFocusEffect(useCallback(() => {
    return () => {
      setRunningPrevOnIndex(-1);
      setOpened(false);
      setCurrentPageIndex(0);
      previousRotation.setValue(1);
      nextRotation.setValue(0);
      lottieAnimation.current && (lottieAnimation.current as any).reset()
    }
  }, []));

  useFocusEffect(useCallback(() => {
    if(openPage) {
      setOpened(true);
    }
  }, [openPage]));

  useEffect(() => {
    if(openPage) {
      nextPage(() => {
        setCurrentPageIndex(pages.length)
        nextRotation.setValue(1);
      });
      lottieAnimation.current && (lottieAnimation.current as any).play()
      /*Animated.sequence([
        Animated.timing(
          lottieAnimation.current && (lottieAnimation.current as any).progress,
            {
              toValue: 1,
              duration: (5000),
            }
          ),
          Animated.timing(
            lottieAnimation.current && (lottieAnimation.current as any).progress,
            {
              toValue: 0,
              duration: (5000),
            }
          )
    ]).start();*/
    }
  }, [openPage]);

  function nextPage(callback?: () => void) {
    Animated.timing(nextRotation, {
      toValue: 1,
      duration: 1200,
      easing: Easing.inOut(Easing.linear),
      useNativeDriver: false,
    }).start(() => {
      nextRotation.setValue(0);
      if(callback){
        callback();
      }
    });
  }

  function previousPage(callback: () => void) {
    Animated.timing(previousRotation, {
      toValue: 0,
      duration: 1200,
      easing: Easing.inOut(Easing.linear),
      useNativeDriver: false,
    }).start(() => {
      if(callback){
        callback();
      }
      previousRotation.setValue(1);
      setRunningPrevOnIndex( - 1);
    });
  }

  return (
    <>
    <View style={{marginTop: 20, marginLeft: 0, transform: [{ scale: 0.8 }]}}>
      <Cover autoOpen={!!openPage} isBookOpened={opened} setOpened={(opened) => setOpened(opened)} baseStyles={baseStyles} coverColors={coverColors} bookCover={bookCover}/>
      {pages.map((page, index) => {
        let rotateYValue = new Animated.Value(1);
        
        if(index === currentPageIndex) {
          rotateYValue = nextRotation;
        }else if(index < currentPageIndex){
          rotateYValue = previousRotation;
        } else if(index >= currentPageIndex) {
          rotateYValue = new Animated.Value(0);
        }

        const priority = pages.length - index;
        const zIndex =  priority + (currentPageIndex <= index || index === runningPrevOnIndex ? 2000 : 1000) ;
        
        return (
          <Page
            key={index}
            rotateYValue={rotateYValue}
            isBookOpened={opened}
            baseStyles={baseStyles}
            renderContent={page.renderContent}
            hasNextPage={index < pages.length - 1}
            hasPreviousPage={index > 0}
            onNextPageClick={() => nextPage(()=> setCurrentPageIndex(index + 1))}
            zIndex={zIndex}
            onPreviousPageClick={() => {
              previousPage(()=> setCurrentPageIndex(index - 1))
              setRunningPrevOnIndex(index - 1);
            }}
          />
        )
      })}
        {openPage && <Page
          key={"openPage"}
          rotateYValue={new Animated.Value(0)}
          isBookOpened={opened}
          baseStyles={baseStyles}
          renderContent={openPage.renderContent}
          hasNextPage={false}
          hasPreviousPage={openPage.hasPreviousPage}
          onPreviousPageClick={() => {
            previousPage(()=> {
              setCurrentPageIndex(pages.length - 1)
              openPage.onPreviousPageClick && openPage.onPreviousPageClick();
            })
            setRunningPrevOnIndex(pages.length - 1);
          }}
          zIndex={1999}
        />}

      {openPage && <AnimatedLottieView
        style={[baseStyles.page_anim, {marginTop: 0, zIndex : 2000 + pages.length - currentPageIndex - 1}]}
        source={require('../../assets/pageFlip.json')}
        ref={lottieAnimation}
        onAnimationFinish={() => {
          setCurrentPageIndex(pages.length + 1)
          console.log('Animation finished');
        }}
      />}
      <BackCover coverColors={coverColors} baseStyles={baseStyles} />
    </View>
  </>
  );
}

const baseStyles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: 420,
  },
  cover_base: {
    height: 690,
    width: 420,
    borderBottomRightRadius: 16,
    borderTopRightRadius: 16,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    backgroundColor: '#013A63',
    overflow: 'hidden',
  },
  page_base: {
    width: 406,
    zIndex: 9999,
  },
  page_anim: {
    ...StyleSheet.absoluteFillObject,
    top: -106,
    left: -449,
    height: 900,
    width: 900,
    zIndex: 989,
  },
});


export default Book;