//@ts-nocheck
import React from "react";
import { View } from 'react-native';
import LatoText from "./LatoText";



const ProfileInfoItem = ({label, icon, value}) => {
  return <View style={{ flex: 1, alignItems: 'center', flexDirection: label ? 'column' : 'row'}}>
      {icon && icon()}
      <LatoText style={{margin:4, marginBottom:6, fontSize:20}}>
        {label}
      </LatoText>
      <LatoText style={{margin:4, marginBottom:6, fontSize:20}}>
        {value}
      </LatoText>
    </View>;
};


export default ProfileInfoItem;