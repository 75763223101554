import "../components/style/modal.css"
import { IconButton } from 'react-native-paper';
import { useConference } from '../hooks/conference';
import { useNavigation } from '@react-navigation/native';

export default function BackToMenu(){
    const {deselectConference} = useConference();
    const navigation = useNavigation();

    return (
        <IconButton
        onPress={() => {
        deselectConference();
        navigation.navigate("Main");
        
        }}
        icon="home"
        color='#000'
        size={40}
        style=
        {{
        position: "fixed",
        top: "5px",
        left: "5fpx",
        zIndex: 998,
        color: '#fff'
        }}
    />
  )
}