import React, { useCallback } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  StatusBar,
  ImageBackground,
  Animated,
  ScrollView,
} from "react-native";
import LatoText from "../../components/LatoText";
import { useConference } from "../../hooks/conference";
import { useMemo, useState } from "react";
import Global from "./Global";
import Personal from "./Personal";
import api from "../../util/axios";
import BackToMenu from "../../components/BackToMenu";
import useGeneralizeColor from "../../hooks/generalizeColor";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import HeaderRight from "../../components/HeaderRight";
import SanduicheMenu from "../sanduicheMenu/SanduicheMenu";
import useImage from "../../hooks/image.js";

const Leaderboard = () => {
  const [selectedPage, setSelectedPage] = useState("Global");
  const [menuVisible, setMenuVisible] = useState(false);
  const navigation = useNavigation();
  const { loading: bgL, error: bgE, image: bg } = useImage("bg.png");

  const togglePage = () => {
    setSelectedPage({ Global: "Personal", Personal: "Global" }[selectedPage]);
  };

  const {
    currentSelectedConference,
    conferences,
    currentSelectedConferenceId,
  } = useConference();
  const { rankingColors } = useGeneralizeColor();
  const conferenceName = useMemo(() => {
    return conferences.find(
      (conference) => conference.slug === currentSelectedConference
    )?.name;
  }, [currentSelectedConference]);
  const [globalData, setGlobalData] = useState([]);
  const [personalData, setPersonalData] = useState({});

  useFocusEffect(
    useCallback(() => {
      async function getPointData() {
        if (!currentSelectedConferenceId) return;
        const global = await api.get(
          "/webapp/game/getRanking/" + currentSelectedConferenceId
        );
        setGlobalData(global.data);
        const personal = await api.get(
          "/webapp/game/getUserHistory/" + currentSelectedConferenceId
        );
        setPersonalData(personal.data);
      }
      getPointData();
    }, [])
  );

  return (
    <>
      <BackToMenu />
      <ImageBackground source={bg} style={{ flex: 1 }}>
        <ScrollView style={[styles.container]}>
          <HeaderRight onPress={() => setMenuVisible(true)} />
          <View style={[styles.header]}>
            <LatoText style={[styles.subtitle]}>{conferenceName}</LatoText>
            <TouchableOpacity onPress={togglePage}>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: rankingColors?.backgroungPointsButton,
                  margin: "auto",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 30,
                  padding: 3,
                }}
              >
                <LatoText
                  style={[
                    selectedPage == "Global"
                      ? { backgroundColor: rankingColors?.selectedPointsButton }
                      : null,
                    {
                      color: "white",
                      padding: 10,
                      borderRadius: 30,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      minWidth: 130,
                      fontSize: 14,
                      lineHeight: '16.8px',
                      height: '40px',
                    },
                  ]}
                >
                  Ranking Global
                </LatoText>
                <LatoText
                  style={[
                    selectedPage == "Personal"
                      ? { backgroundColor: rankingColors?.selectedPointsButton }
                      : null,
                    {
                      color: "white",
                      padding: 10,
                      borderRadius: 30,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      minWidth: 130,
                      fontSize: 14,
                      lineHeight: '16.8px',
                      height: '40px',
                    },
                  ]}
                >
                  Meus Pontos
                </LatoText>
              </View>
            </TouchableOpacity>
          </View>
          <View style={[styles.footer]}>
            <LatoText black style={[styles.title, { color: "black" }]}>
              {selectedPage == "Global" ? (
                <Global data={globalData}></Global>
              ) : (
                <Personal userHistory={personalData}></Personal>
              )}
            </LatoText>
          </View>
        </ScrollView>
        {menuVisible && (
          <SanduicheMenu
            onMenuItemPress={(screen) => {
              setMenuVisible(false);
              navigation.navigate(screen);
            }}
            onClose={() => setMenuVisible(false)}
          />
        )}
      </ImageBackground>
    </>
  );
};

export default Leaderboard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 25,
    paddingRight: 25,
  },
  header: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 30,
    maxWidth: 600,
    margin: "auto",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 8,
    color: "black",
    marginTop: 20,
  },
  button: {
    flex: 1,
    paddingHorizontal: 16,
    marginTop: 40,
    alignItems: "center",
    width: "100%",
  },
  footer: {
    backgroundColor: "#fff",
    borderRadius: 30,
    paddingTop: 5,
    margin: "auto",
    marginTop: 30,
    width: "100%",
    maxWidth: 500,
    marginBottom: 100,
  },
  selector: {
    width: 270,
    margin: "auto",
    marginTop: 20,
  },
});
