import React, { useMemo } from 'react';
import { FlatList, StyleSheet, View, Text, Image, TouchableWithoutFeedback, TouchableOpacity } from 'react-native';
import LatoText from "../../LatoText";
import contactLine from "../../../assets/ContactLine.png"
import brasaStamp from "../../../assets/BrasaStamp.png"
import useImage  from '../../../hooks/image.js';

const notebookLines = [false, false, false, true, true, true, true, true, true, true, true, true, true, false, false];

const LineDivider = () => (
  <View style={{height: 2, backgroundColor: 'rgba(0, 0, 0, 0.1)'}}/>
);

const NotebookPage = ({onItemClick, items, renderItem}) => {
  const data = useMemo(() => {
    let i = 0;
    return notebookLines.map((item) => {
      if(item) {
        if(i < items.length) {
          return items[i++];
        }
        return false;
      }
      return item;
    });
  }, [items]);


  return <View
      style={{flex: 1}}
    >
      <FlatList
        data={data}
        ItemSeparatorComponent={LineDivider}
        renderItem={({item}) => {
          return <View style={styles.item}>
          {!!item && (
            <TouchableOpacity
                onPress={() => {
                  if(onItemClick) onItemClick(item);
              }}>
              {
                (!!renderItem && renderItem(item))
              }
            </TouchableOpacity>)
          }
        </View>
        }}
      />
      <View style={styles.header_container}>
        <LatoText bold style={styles.text_header}>CONTATOS</LatoText>
        <Image source={contactLine} style={{ width: 207.5, height: 16.5 }} />
      </View>
      <View style={styles.line_margin}/>
      <Image source={brasaStamp} style={{position: 'absolute', top: 25, right: 10, width: 110, height: 116}} />
    </View>
}

export default NotebookPage;

const styles = StyleSheet.create({
  header_container: {
    position: 'absolute', 
    top: 50, 
    left: 35,
  },
  text_header: {
    fontSize: 36,
  },
  item: {
    height: 43,
    justifyContent: 'center',
    paddingLeft: 35,
  },
  line_margin: {
    ...StyleSheet.absoluteFillObject,
    left: 25,
    flex: 1,
    width: 3,
    backgroundColor: 'rgba(200, 50, 0, 0.4)',
  }
});