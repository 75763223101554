import { useFocusEffect,  useRoute } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo } from 'react';
import { View, Dimensions, StyleSheet, FlatList, TouchableWithoutFeedback, ImageBackground, Image, TouchableOpacity, Platform } from 'react-native';

import * as Linking from 'expo-linking';
import Book from '../../components/Book';
import LatoText from '../../components/LatoText';
import { useConference } from '../../hooks/conference';
import IInitiative from '../../interfaces/IInitiative';
import { getParticipantInitiatives, submitRating } from '../../services/initiativesService';
import useImage  from '../../hooks/image.js';
import coverColors from '../../assets/cover-colors.json';
import Loading from '../../hooks/loading'

import ScrapbookPage from '../../components/Book/Pages/ScrapbookPage';
import {  MaterialCommunityIcons } from '@expo/vector-icons';
import RatingBar from '../../components/RatingBar';
import { debounce } from 'lodash';
import BackToMenu from '../../components/BackToMenu';
import api from '../../util/axios';
import {useAuth} from "../../hooks/auth";

type InitiativeWithRating = {
  rating?: number,
  initiative: IInitiative,
};

const InitiativesBook = () => {
  const {currentSelectedConference} = useConference();
  const {height} = Dimensions.get("screen");
  const route = useRoute()
  const [initiatives, setInitiatives] = React.useState<InitiativeWithRating[]>([]);
  const [pageToOpen, setPageToOpen] = React.useState<InitiativeWithRating>(route.params && (route.params as any)?.autoOpenContact);
  const height_logo = height * 0.18;

  const { image: bg, loading: bgLoading } = useImage('bg.png')
  const { image: bookCoverIMG, loading: bookCoverLoading } = useImage('BookInitiativesCover.png')
  const { image: brasaStamp, loading: brasaStampLoading } = useImage('BrasaStamp.png')
  const { image: scratchPaper, loading: scratchPaperLoading } = useImage('ScratchPage.png')
  const auth = useAuth();

  useFocusEffect(useCallback(()=> {
    return () => {
      setPageToOpen(null);
    }
  },[]));

  const pages = useMemo(() => {
    const pages = [];
    const chunkSize = 8; //Number of stickers per page
    if(initiatives.length === 0) {
      return [{
        renderContent: () => (
          <ScrapbookPage items={[]} renderItem={null} />
        )
      }]
    }
    for (let i = 0; i < initiatives.length; i += chunkSize) {
        const chunk = initiatives.slice(i, i + chunkSize);
        pages.push(
          {
            renderContent: () => (
              <ScrapbookPage maxStickerNum={12} items={chunk} renderItem={(initiative : InitiativeWithRating) => (
                  <TouchableWithoutFeedback onPress={() => setPageToOpen(initiative)}>
                    <Image source={{uri: `${api.getUri()}/${initiative.initiative.sticker.split("https://brasa-api.s3.amazonaws.com/")[1]}?auth=${auth.user.auth.token}`}}  style={{height: '100%', transform: [{scale: 1.2}]}} />
                  </TouchableWithoutFeedback>
              )}/>
            )
          });
    }
    return pages;
  }, [initiatives]);


  useEffect(() => {
    setPageToOpen(route.params && (route.params as any)?.autoOpenInitiative as InitiativeWithRating);
  }, [route.params && (route.params as any)?.autoOpenInitiative]);

  useFocusEffect(useCallback(() => {
    async function fetch() {
      try {
        const response = await getParticipantInitiatives(currentSelectedConference);
        setInitiatives(response.data.initiatives);
      } catch (error) {
        console.log(error);
      }
    }
    if(currentSelectedConference) {
      fetch();
    }
  }, []));

  useEffect(() => {
    if(pageToOpen && pageToOpen.rating === undefined && pageToOpen.initiative) {
      const initiative = initiatives.find(i => i.initiative.id === pageToOpen.initiative.id);
      if(initiative) {
        setPageToOpen(initiative);
      }
    }
  }, [initiatives]);


  const submitRatingToAPI = async rating => {
    try {
      await submitRating(currentSelectedConference, pageToOpen.initiative.id, rating);
      console.log("Rating submitted");
    } catch {
      console.error("Error submitting rating");
    }
  };
  const debounceSubmitRatingToAPI = useCallback(
    debounce(submitRatingToAPI, 500)
  , [pageToOpen]);

  return (
    <View style={{
      flex: 1,
    }}>
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: -50}}>
      
      {(!bgLoading && !brasaStampLoading && !bookCoverLoading && !scratchPaperLoading) ? (
      <Book
        bookCover={bookCoverIMG}
        coverColors={coverColors[currentSelectedConference]["initiative"]}
        openPage={pageToOpen && pageToOpen.initiative ? {
              hasPreviousPage: true,
              onPreviousPageClick: () => {
                setPageToOpen(null);
              },
          renderContent: () => (
            <View style={styles.itemPageRoot}>
              <Image source={scratchPaper} style={{width: 406, height: 674}}/>
              <View style={styles.itemPageContainer}>
                <View style={styles.header}>
                  <Image source={{uri: `${api.getUri()}/${pageToOpen.initiative.sticker.split("https://brasa-api.s3.amazonaws.com/")[1]}?auth=${auth.user.auth.token}`}}  style={{height: 130, width: 130, transform: [{scale: 1.7}]}} />
                </View>
                <View style={styles.content}>
                  <LatoText bold style={styles.itemPageTitle}>{pageToOpen.initiative.name}</LatoText>
                  <LatoText adjustsFontSizeToFit style={styles.itemPageDescription}>{pageToOpen.initiative.description}</LatoText>
                </View>
                {/* //TODO Fix this to mobile */}
                {pageToOpen.rating !== undefined && <RatingBar maxRating={5} defaultRating={pageToOpen.rating || 0} onChange={debounceSubmitRatingToAPI} />}
                {pageToOpen.initiative.locationName  && (
                  <View style={styles.footer}>
                    <View style={styles.locationContainer}>
                      <View style={styles.textContainer}>
                        <LatoText style={{fontSize: 18, marginLeft: 0, color: 'black'}}><MaterialCommunityIcons name="office-building" size={24} color="black" />  {pageToOpen.initiative.locationName}</LatoText>
                      </View>
                    </View>
                    {pageToOpen.initiative.locationGooglePlaceId ? <TouchableOpacity onPress={() => Platform.OS === 'web' ? window.open(`https://www.google.com/maps/dir/?api=1&destination=${pageToOpen.initiative.locationName}&destination_place_id=${pageToOpen.initiative.locationGooglePlaceId}&travelmode=walking`, "_blank") : {}}> 
                      <View style={styles.directionsContainer}>
                        <View style={styles.textContainer}>
                          <LatoText style={{fontSize: 18, marginLeft: 0, color: 'black'}}><MaterialCommunityIcons name="directions" size={24} color="black" /> Como chegar?</LatoText>
                        </View>
                      </View>
                    </TouchableOpacity>
                  : null}
                  </View>
                )}
              </View>
            </View>
          ),
        } : null}
        pages={pages}
      />) : (
        <Loading/>
      )}

      
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  itemPageRoot: {
    flex: 1,
  },
  itemPageContainer: {
    padding: 30,
    ...StyleSheet.absoluteFillObject,
    paddingBottom: 80
  },
  footer: {
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    left: 20
  },
  directionsContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    flex: 1,
  },
  locationContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    flex: 1,
  },
  iconContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 5,
  },
  textContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    marginLeft: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    justifyContent: 'center', 
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'black', 
  },
  itemPageTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    left: 5,
    color: 'black'
  },
  itemPageDescription: {
    fontSize: 20,
    marginTop: 16,
    textAlign: 'center',
    color: 'black'
  },
  header: {
    flex: 1,
    width: 180,
    height: 180,
    marginLeft: '30%',
    marginRight: 'auto',
    justifyContent: 'center',
  },
  content: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
export default InitiativesBook;